import axios from "axios";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { apiBaseUrl as baseURL } from "../config/ApiConfig";
import { Plant, Site } from "../types/QualityControlModels";
import { User } from "@auth0/auth0-react";

const axiosClient = axios.create({ baseURL });

export const useGetUserData = ({ accessToken, accountId }) => {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    return useQuery(
        ["userData", accessToken],
        async () => {
            const response = await fetch(`https://graph.microsoft.com/v1.0/users/${accountId}`, {
                method: "GET",
                headers: headers
            });
            const data = await response.json();
            return data ?? [];
        },
        { staleTime: Infinity, enabled: !!accessToken }
    );

}

export const useGetUsersInGroup = ({ accessToken, groupId }) => {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    return useQuery(
        ["usersInGroup", groupId, accessToken],
        async () => {
            const response = await fetch(`https://graph.microsoft.com/v1.0/groups/${groupId}/members`, {
                method: "GET",
                headers: headers
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            return data.value ?? [];
        },
        { staleTime: Infinity, enabled: !!accessToken && !!groupId }
    );
};

export const useGetUsers = () => {
    return useQuery(
        ["users"],
        async () => {
            const response = await axiosClient.get(`/api/users`);
            return (response?.data as User[]) ?? [];
        },
        { staleTime: Infinity }
    );
};

export const useGetSites = (options = {}) => {
    return useQuery(
        ["sites"],
        async () => {
            const response = await axiosClient.get(`/api/site`);
            return (response?.data as Site[]) ?? [];
        },
        { staleTime: Infinity, ...options }
    );
};

export const useAddOrUpdateSiteMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async ({ id, ...updatedSite }: { id: number } & Site) => {
            if (updatedSite.isNew) {
                return await axiosClient.post(`/api/site`, updatedSite);
            } else {
                return await axiosClient.put(`/api/site/${id}`, updatedSite);
            }
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["sites"]);
            },
        }
    );
};

export const useGetPlants = (options = {}) => {
    return useQuery(
        ["plants"],
        async () => {
            const response = await axiosClient.get(`/api/plant`);
            return (response?.data as Plant[]) ?? [];
        },
        { staleTime: Infinity, ...options }
    );
};

export const useAddOrUpdatePlantMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async ({ site, locations, ...updatedPlant }: { id: number } & Plant) => {
            if (updatedPlant.isNew) return await axiosClient.post(`/api/plant`, updatedPlant);
            else {
                return await axiosClient.put(`/api/plant/${updatedPlant.id}`, updatedPlant);
            }
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["plants"]);
            },
        }
    );
};

export const useAddOrUpdateTestTypeMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async ({ ...updatedTestType }: { id: number } & Plant) => {
            if (updatedTestType.isNew) return await axiosClient.post(`/api/testType`, updatedTestType);
            else {
                return await axiosClient.put(`/api/plant/${updatedTestType.id}`, updatedTestType);
            }
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["plants"]);
            },
        }
    );
};

export const useAddOrUpdateCompositeTypeMutation = () => {
    const queryClient = useQueryClient();
    return useMutation(
        async ({ ...updatedCompositeType }: Plant) => {
            if (updatedCompositeType.isNew) return await axiosClient.post(`/api/compositeType`, updatedCompositeType);
            else {
                return await axiosClient.put(`/api/plant/${updatedCompositeType.id}`, updatedCompositeType);
            }
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["plants"]);
            },
        }
    );
};

export const useGetSops = () => {
    return useQuery(
        ["sops"],
        async () => {
            const { data } = await axiosClient.get(`/api/Document/sop`);
            return data;
        },
        { staleTime: 10 }
    );
};

export const useGetSop = (fileName) => {
    return useQuery(["sop", fileName], async () => {
        const { data } = await axiosClient.get(`/api/Document/sop/${fileName}`);
        return data;
    });
};

export const useSaveSopMutation = () => {
    const queryClient = useQueryClient();

    const saveSopMutation = useMutation(
        ({
            fileName,
            blob,
            category,
            description,
            displayName,
        }: {
            fileName: string;
            blob: string;
            category: string;
            description: string;
            displayName: string;
        }) => {
            return axiosClient.put(
                `/api/Document/sop/save/${fileName}`,
                { blob, category, description, displayName },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["sops"]);
            },
        }
    );
    return saveSopMutation;
};

export const useUpdateSopMutation = () => {
    const queryClient = useQueryClient();

    const saveSopMutation = useMutation(
        ({
            fileName,
            category,
            description,
            displayName,
        }: {
            fileName: string;
            category: string;
            description: string;
            displayName: string;
        }) => {
            return axiosClient.put(
                `/api/Document/sop/update/${fileName}`,
                { category, description, displayName },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["sops"]);
            },
        }
    );
    return saveSopMutation;
};

export const useDeleteSopMutation = () => {
    const queryClient = useQueryClient();

    return useMutation(
        async (fileName) => {
            const response = await axiosClient.delete(`/api/Document/sop/delete/${fileName}`);
            return response.data; // Assuming the API returns some data upon successful deletion
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["sops"]);
            },
        }
    );
};
