import { useMemo } from "react";
import { AsyncSelect } from "./AsyncSelect";
import { useGetUsersInGroup } from "../../queries/AdminQueries";
import { useAcquireAccessToken } from "../../hooks/general/useAcquireAccessToken";

export const UserSelect = ({
    userGroup = null,
    userRole = null,
    selectedUser,
    handleUserChange,
    disabled,
    isLoading = false,
    size = "",
}) => {
    const silicoreUsersGroupId = "845f406e-6945-4015-989a-ac14d7cb78e8";
    const { accessToken } = useAcquireAccessToken();
    const { data: users } = useGetUsersInGroup({ accessToken, groupId: silicoreUsersGroupId });
    const options = useMemo(() => {
        return users?.filter((user) => user["@odata.type"] !== "#microsoft.graph.group")?.map((user) => ({ value: user?.id, label: user?.displayName })) ?? [];
    }, [users]);

    const label = userRole ?? "User";

    const selected = options?.find((option) => option?.value === selectedUser) ?? null;

    return (
        <AsyncSelect
            label={label}
            options={options}
            selectedOptions={selected}
            handleChange={handleUserChange}
            isLoading={isLoading}
            disabled={disabled}
            size={size}
        />
    );
};
