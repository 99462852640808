import { Box, Chip } from "@mui/material";
import { SampleStatus, SampleStatusToString } from "../../../types/QualityControlEnums";
import NewReleasesRoundedIcon from "@mui/icons-material/NewReleasesRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import PriorityHighRoundedIcon from "@mui/icons-material/PriorityHighRounded";
import { Pending } from "@mui/icons-material";

export const SampleStatusChip = ({ status }: { status: SampleStatus }) => {
    switch (status) {
        case 0:
            return (
                <Chip
                    label={SampleStatusToString(status)}
                    icon={<NewReleasesRoundedIcon style={{ color: "#fff" }} />}
                    sx={(theme) => ({
                        backgroundColor: theme.palette.primary.lighter,
                        color: theme.palette.primary.contrastText,
                    })}
                />
            );
        case 1:
            return (
                <Chip
                    label={SampleStatusToString(status)}
                    icon={<Pending style={{ color: "#fff" }} />}
                    sx={(theme) => ({
                        backgroundColor: theme.palette.primary.light,
                        color: theme.palette.primary.contrastText,
                    })}
                />
            );
        case 2:
            return <Chip color="success" label={SampleStatusToString(status)} icon={<CheckCircleRoundedIcon />} />;
        case 3:
            return <Chip color="warning" label={SampleStatusToString(status)} icon={<PriorityHighRoundedIcon />} />;
        default:
            break;
    }
};

export const SampleStatusDisplay = ({ status }: { status: SampleStatus }) => {
    switch (status) {
        case 0:
            return (
                <Box justifyContent="space-between">
                    <NewReleasesRoundedIcon sx={{ color: "primary.lighter" }} /> {SampleStatusToString(status)}
                </Box>
            );
        case 1:
            return (
                <Box justifyContent="space-between">
                    <Pending sx={{ color: "primary.light" }} /> {SampleStatusToString(status)}
                </Box>
            );
        case 2:
            return (
                <Box justifyContent="space-between">
                    <CheckCircleRoundedIcon sx={{ color: "success.main" }} /> {SampleStatusToString(status)}
                </Box>
            );
        case 3:
            return (
                <Box justifyContent="space-between">
                    <PriorityHighRoundedIcon color="warning" />
                    {SampleStatusToString(status)}
                </Box>
            );
        default:
            break;
    }
};
