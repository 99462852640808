import { useMemo } from "react";
import { useAddOrUpdateTestTypeMutation } from "../../queries/AdminQueries";
import { useGetTestTypes } from "../../queries/QualityControlQueries";
import { EntityTable } from "../../utilities/EntityTable";
import { EntityTableContainer } from "../../utilities/EntityTableContainer";

export const ManageTestTypes = () => {
    const { data, isLoading } = useGetTestTypes();

    const columnFields = useMemo(
        () => [
            { field: "id", headerName: "ID", flex: 2 },
            { field: "name", headerName: "Name", flex: 2, editable: true },
            { field: "description", headerName: "Description", flex: 2, editable: true },
            {
                field: "isActive",
                headerName: "Visible",
                flex: 2,
                type: "boolean",
                editable: true,
            },
        ],
        []
    );

    return (
        <>
            {data && (
                <EntityTableContainer
                    title="Test Types"
                    table={
                        <EntityTable
                            entity={data}
                            columnFields={columnFields}
                            useAddOrUpdateEntity={useAddOrUpdateTestTypeMutation}
                            isLoading={isLoading}
                        />
                    }
                />
            )}
        </>
    );
};
