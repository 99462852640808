import React from "react";
import { Box, CircularProgress } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useGetWashLogEntries } from "../../../queries/QualityControlQueries";

export const SieveSetWashLog = () => {
    const { data: washLogs, isLoading: isLoadingWashLog } = useGetWashLogEntries();

    const columns = [
        { field: "siteId", headerName: "Site", flex: 2, valueGetter: ({ row }) => row.sieveSet.sites.map((site) => site.name).join(" | ") },
        {
            field: "sieveSetId",
            headerName: "Sieve Set",
            flex: 2,
            valueGetter: ({ row }) => row.sieveSet.name,
        },
        { field: "timestamp", headerName: "Timestamp", flex: 2 },
        { field: "username", headerName: "User", flex: 2 },
    ];

    return (
        <Box height={500}>
            {isLoadingWashLog && <CircularProgress />}
            {washLogs && !isLoadingWashLog && (
                <DataGrid columns={columns} rows={washLogs} components={{ Toolbar: GridToolbar }} />
            )}
        </Box>
    );
};
