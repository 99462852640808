import { TableHead, TableRow, TableCell, TableBody, TextField, Table, InputAdornment } from "@mui/material";
import { useSieveAnalysisCalculationHooks } from "./Calculations";
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { SieveAnalysis, SieveSetAnalysis, Range } from "../../../types/QualityControlModels";
import { useGetRangeSettingGroupBySite } from "../../../queries/QualityControlQueries";
import { useCurrentSite } from "../../../hooks/general/useCurrentSite";

export const SampleSieveRangesTable = ({
    sieveAnalyses,
    setSieveSetAnalysis,
    oversizePercent,
}: {
    sieveAnalyses: SieveAnalysis[];
    setSieveSetAnalysis: Dispatch<SetStateAction<SieveSetAnalysis>>;
    oversizePercent: number;
}) => {
    const { site } = useCurrentSite();
    const [ranges, setRanges] = useState<Range[]>(null);
    const { data: sieveGroup, isLoading: isLoadingRangeSettingGroup } = useGetRangeSettingGroupBySite(site?.id);

    const { calculateSieveRange } = useSieveAnalysisCalculationHooks();

    const isReady = useMemo(
        () =>
            sieveAnalyses?.some((sieveAnalysis: SieveAnalysis) => Number(sieveAnalysis.percentFinal) > 0) &&
            !isLoadingRangeSettingGroup,
        [isLoadingRangeSettingGroup, sieveAnalyses]
    );

    useEffect(() => {
        if (isReady && sieveGroup?.rangeSettingGroup?.rangeSettingGroupRangeSettings?.length > 0) {
            setRanges(
                sieveGroup?.rangeSettingGroup.rangeSettingGroupRangeSettings?.map(({ rangeSetting }) => ({
                    rangeSettingId: rangeSetting.id,
                    rangeSetting,
                    value:
                        rangeSetting.endSieve === 10
                            ? Number(oversizePercent ?? 0)
                            : calculateSieveRange({
                                  analyses: sieveAnalyses,
                                  rangeSetting,
                              }),
                }))
            );
        }
    }, [
        calculateSieveRange,
        isReady,
        oversizePercent,
        sieveAnalyses,
        sieveGroup?.rangeSettingGroup.rangeSettingGroupRangeSettings,
    ]);

    useEffect(() => {
        if (ranges) {
            setSieveSetAnalysis((prevState: SieveSetAnalysis) => ({ ...prevState, ranges }));
        }
    }, [ranges, setSieveSetAnalysis]);

    return isReady ? (
        <Table>
            <TableHead>
                <TableRow>
                    {sieveGroup?.rangeSettingGroup?.rangeSettingGroupRangeSettings?.map(({ rangeSetting }) => (
                        <TableCell key={rangeSetting.id}>{rangeSetting.name}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    {ranges?.map((range: Range, index: number) => (
                        <TableCell key={index}>
                            <TextField
                                key={index}
                                value={range?.value || 0}
                                disabled
                                size="small"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                                sx={{
                                    // Make disabled input text darker
                                    "& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled": {
                                        WebkitTextFillColor: "rgba(83, 83, 83, 1)", // Use primary text color
                                    },
                                }}
                            />
                        </TableCell>
                    ))}
                </TableRow>
            </TableBody>
        </Table>
    ) : (
        <>No Sieve Ranges to show</>
    );
};
