import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, createTheme } from "@mui/material";
import { themeOptions } from "./shared/theme";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./shared/config/AuthConfig";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const theme = createTheme(themeOptions);
const msalInstance = new PublicClientApplication(msalConfig);

root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <App />
            </ThemeProvider>
        </MsalProvider>
    </React.StrictMode>
);
