import { Card, CardContent, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { useGetPlantDevicesByPlantId } from "../../../queries/QualityControlQueries";
import { PlantDevice, PlantSetting, Sample, SampleLocation } from "../../../types/QualityControlModels";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { isEqual } from "lodash";
import { isValidNumber } from "./Calculations";

export const SamplePlantSettings = ({
    sample,
    sampleUpdate,
    setSampleUpdate,
    showTitle,
    isReadOnly,
    disabled,
}: {
    sample: Sample;
    sampleUpdate: Sample;
    setSampleUpdate: (any) => void;
    showTitle: boolean;
    isReadOnly: boolean;
    disabled: boolean;
}) => {
    const { data: plantDevices, isLoading } = useGetPlantDevicesByPlantId(
        sampleUpdate.location?.plantId ?? sample.location?.plantId
    );
    const [plantSettings, setPlantSettings] = useState<PlantSetting[]>([]);

    const columnWidth = useMemo(() => {
        return Math.max(12 / plantDevices?.length || 1, 3);
    }, [plantDevices?.length]);

    const handleChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, plantDevice: PlantDevice) => {
        const value = parseFloat(event.target.value);
        setPlantSettings((prevState: PlantSetting[]) => [
            ...prevState.map((plantSetting: PlantSetting) => {
                if (plantSetting.plantDeviceId === plantDevice.id) {
                    return {
                        ...plantSetting,
                        value: isValidNumber(value) ? value : "",
                    };
                }
                return { ...plantSetting };
            }),
        ]);
    };

    useEffect(() => {
        if (plantDevices && plantSettings.length === 0) {
            setPlantSettings(
                plantDevices?.map((plantDevice: PlantDevice) => ({
                    plantDeviceId: plantDevice.id,
                    plantDevice: plantDevice,
                    value:
                        sampleUpdate.plantSettings?.find((plantSetting) => plantSetting.plantDeviceId === plantDevice.id)
                            ?.value ??
                        sample.plantSettings?.find((plantSetting) => plantSetting.plantDeviceId === plantDevice.id)?.value ??
                        "",
                }))
            );
        }
    }, [plantDevices, plantSettings.length, sample.plantSettings, sampleUpdate.plantSettings]);

    useEffect(() => {
        if (
            plantSettings?.length > 0 &&
            plantSettings?.some((plantSetting) => !!plantSetting.value) &&
            !isEqual(sampleUpdate.plantSettings, plantSettings)
        ) {
            setSampleUpdate((prevState: Sample) => ({ ...prevState, plantSettings }));
        }
    }, [plantSettings, sampleUpdate.plantSettings, setSampleUpdate]);

    return (
        <Card elevation={0}>
            <CardContent>
                {(sampleUpdate?.location || sample?.location) && !isLoading && (
                    <Grid container spacing={2}>
                        {showTitle && (
                            <Grid item xs={12}>
                                <Typography variant="h6">Plant Settings</Typography>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Typography variant="subtitle2">
                                {sampleUpdate?.location?.plant?.name ?? sample.location?.plant?.name ?? ""} Settings
                            </Typography>
                        </Grid>
                        {plantDevices?.map((plantDevice: PlantDevice, index: number) => (
                            <Grid key={index} item xs={columnWidth}>
                                <TextField
                                    key={index}
                                    label={plantDevice.name}
                                    fullWidth
                                    value={
                                        plantSettings?.find((plantSetting) => plantSetting.plantDeviceId === plantDevice.id)
                                            ?.value ?? null
                                    }
                                    onChange={(event) => handleChange(event, plantDevice)}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">{plantDevice.units}</InputAdornment>,
                                    }}
                                    disabled={disabled || isReadOnly}
                                    size="small"
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </CardContent>
        </Card>
    );
};
