import { useEffect, useState } from "react";
import { loginRequest } from "../../config/AuthConfig";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";

export const useAcquireAccessToken = () => {
    const [accessToken, setAccessToken] = useState(null);
    const { accounts, instance } = useMsal();

    useEffect(() => {
        async function initialize() {
            await instance.initialize();
        }

        initialize().then(() => {
            instance
                .acquireTokenSilent({
                    ...loginRequest,
                    account: accounts?.[0],
                })
                .then((response) => {
                    setAccessToken(response.accessToken);
                })
                .catch(async (error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        // fallback to interaction when silent call fails
                        return instance.acquireTokenPopup(loginRequest);
                    }
                });
        });
    }, [accounts, instance]);

    return { accessToken };
};
