import { utils, writeFile } from "xlsx";
import { CrushAnalysis, PlantSetting, Range, SieveAnalysis } from "../types/QualityControlModels";

export function formatSieveAnalysesForExport(sieveAnalyses: SieveAnalysis[]) {
    return sieveAnalyses?.length
        ? Object.assign(
              {},
              ...sieveAnalyses.map((sieveAnalysis) => ({
                  [sieveAnalysis.sieve.size]: sieveAnalysis.percentFinal,
              }))
          )
        : [];
}

export function formatCrushAnalysesForExport(crushAnalyses: CrushAnalysis[]) {
    return crushAnalyses?.length
        ? Object.assign(
              {},
              ...crushAnalyses.map((crushAnalysis) => ({
                  [crushAnalysis.name]: crushAnalysis.average,
              }))
          )
        : [];
}

export function formatRangesForExport(ranges: Range[]) {
    return ranges?.length
        ? Object.assign(
              {},
              ...ranges.map((range) => ({
                  [range.rangeSetting.name]: range.value,
              }))
          )
        : [];
}

export function formatPlantSettingsForExport(plantSettings: PlantSetting[]) {
    return plantSettings?.length
        ? Object.assign(
              {},
              ...plantSettings.map((plantSetting) => ({
                  [plantSetting.plantDevice?.name]: plantSetting.value,
              }))
          )
        : [];
}

export function samplesToExcel(samples: any[]) {
    if (samples.length) {
        const workbook = utils.book_new();
        const worksheetData = samples.map((sample) => {
            const formattedSieveAnalyses = formatSieveAnalysesForExport(sample?.sieveSetAnalysis?.sieveAnalyses);
            const formattedCrushAnalyses = formatCrushAnalysesForExport(sample?.crushAnalyses);
            const formattedRanges = formatRangesForExport(sample?.sieveSetAnalysis?.ranges);
            const formattedPlantSettings = formatPlantSettingsForExport(sample?.plantSettings);

            return {
                ...sample,
                ...formattedSieveAnalyses,
                ...formattedCrushAnalyses,
                ...formattedPlantSettings,
                ...formattedRanges,
            };
        });
        const worksheet = utils.json_to_sheet(worksheetData);

        utils.book_append_sheet(workbook, worksheet);
        writeFile(workbook, `${new Date().toISOString()}-${samples.length}-samples-export.xlsx`);
    }
}
