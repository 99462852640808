import { useMemo } from "react";
import { useGetUserData } from "../../queries/AdminQueries";
import { useAcquireAccessToken } from "./useAcquireAccessToken";
import { useMsal } from "@azure/msal-react";

export const useUserInfo = () => {
    const { accounts } = useMsal();
    const account = useMemo(() => accounts?.[0], [accounts]);
    const { accessToken } = useAcquireAccessToken();
    const { data: userInfo, isLoading } = useGetUserData({ accessToken, accountId: account?.localAccountId });

    return { userInfo, isLoading };
};