import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import { useDeleteSopMutation, useGetSops, useSaveSopMutation, useUpdateSopMutation } from "../../queries/AdminQueries";
import { Buffer } from "buffer";
import { PaperItem } from "../../utilities/PaperItem";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
    styled,
} from "@mui/material";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import { CloudUpload, Delete, Edit, Visibility } from "@mui/icons-material";
import { toast } from "react-toastify";
import { RelativeTime } from "../../utilities/DateUtilities";

export const DocumentManager = () => {
    const [tabValue, setTabValue] = React.useState("1");
    const [document, setDocument] = useState<File>(null);
    const [fileDisplayName, setFileDisplayName] = useState(null);
    const [fileDescription, setFileDescription] = useState(null);
    const [fileCategory, setFileCategory] = useState("SOP");
    const { data: documents, isLoading: isFetchingDocuments } = useGetSops();
    const { mutate: saveDocument, isLoading: isSavingDocument } = useSaveSopMutation();

    const onFileChange = (event) => {
        setDocument(event.target.files[0]);
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    const handleDocumentUpload = async () => {
        if (document) {
            const reader = new FileReader();
            reader.onload = async (e) => {
                const blob = e.target?.result as string;
                saveDocument(
                    {
                        fileName: document.name,
                        blob: blob.split(',')[1], // Remove the base64 header if read as data URL
                        category: fileCategory,
                        description: fileDescription,
                        displayName: fileDisplayName ?? document.name,
                    },
                    {
                        onSuccess: () => {
                            toast.success("File uploaded successfully");
                            resetDocumentUpload();
                            setTabValue("1");
                        },
                    }
                );
            };
            reader.readAsDataURL(document); // Read file as data URL to get base64 string
        }
    };

    const resetDocumentUpload = () => {
        setDocument(null);
        setFileCategory("SOP");
        setFileDescription(null);
        setFileDisplayName(null);
    };

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <PaperItem>
                        <Grid item xs={12}>
                            <Box sx={{ width: "100%" }}>
                                <TabContext value={tabValue}>
                                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                        <TabList onChange={handleTabChange} aria-label="QC Documents">
                                            <Tab label="Documents" value="1" />
                                            <Tab
                                                label={
                                                    <Stack
                                                        direction="row"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        spacing={1}
                                                    >
                                                        <Typography variant="button">Upload</Typography> <CloudUpload />
                                                    </Stack>
                                                }
                                                value="2"
                                            />
                                        </TabList>
                                    </Box>
                                    <TabPanel value="1" sx={{ maxHeight: "75vh", overflow: "auto" }}>
                                        {isFetchingDocuments && <CircularProgress />}
                                        {documents?.length > 0 ? <DocumentTable documents={documents} /> : null}
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <Grid container spacing={2}>
                                            {document ? (
                                                <>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            label="File Name"
                                                            value={document?.name}
                                                            disabled
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Select
                                                            label="Category"
                                                            value={fileCategory}
                                                            onChange={(e) => setFileCategory(e.target.value)}
                                                            size="small"
                                                            fullWidth
                                                        >
                                                            <MenuItem value="SOP">Standard Operating Procedure</MenuItem>
                                                        </Select>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            label="Display Name"
                                                            value={fileDisplayName ?? document.name}
                                                            onChange={(e) => setFileDisplayName(e.target.value)}
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            label="Description"
                                                            value={fileDescription}
                                                            onChange={(e) => setFileDescription(e.target.value)}
                                                            size="small"
                                                            fullWidth
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} justifyContent="flex-end">
                                                        <Button
                                                            variant="contained"
                                                            startIcon={<CloudUpload />}
                                                            onClick={handleDocumentUpload}
                                                        >
                                                            Upload File
                                                        </Button>
                                                    </Grid>
                                                </>
                                            ) : (
                                                <Button component="label" variant="contained">
                                                    Select file
                                                    <VisuallyHiddenInput
                                                        type="file"
                                                        accept=".pdf"
                                                        onChange={onFileChange}
                                                        multiple={false}
                                                    />
                                                </Button>
                                            )}
                                        </Grid>
                                    </TabPanel>
                                </TabContext>
                            </Box>
                        </Grid>
                    </PaperItem>
                </Grid>
            </Grid>
        </>
    );
};

const DocumentTable = ({ documents }) => {
    const [documentToUpdate, setDocumentToUpdate] = useState(null);
    const [documentToDelete, setDocumentToDelete] = useState(null);
    const { mutate: deleteDocument, isLoading: isDeletingDocument } = useDeleteSopMutation();
    const { mutate: updateDocument, isLoading: isUpdatingDocument } = useUpdateSopMutation();

    const handleStartEditDocument = (document) => {
        setDocumentToUpdate(document);
    };

    const handleUpdateDocument = ({ category, description, displayName }) => {
        updateDocument(
            {
                fileName: documentToUpdate.name,
                category,
                description,
                displayName,
            },
            {
                onSuccess: () => {
                    toast.success("File uploaded successfully");
                    setDocumentToUpdate(null);
                },
            }
        );
    };

    const handleShowDeleteConfirmDialog = (document) => {
        setDocumentToDelete(document);
    };

    const handleDeleteDocument = (documentName) => {
        deleteDocument(documentName, {
            onSuccess: () => {
                toast.success("File deleted successfully");
                setDocumentToUpdate(null);
            },
        });
    };

    return (
        <>
            {!!documentToUpdate && (
                <DocumentUpdateDialog
                    open={!!documentToUpdate}
                    onClose={() => setDocumentToUpdate(null)}
                    document={documentToUpdate}
                    handleDocumentUpload={handleUpdateDocument}
                />
            )}
            {!!documentToDelete && (
                <ConfirmDocumentDeleteDialog
                    open={!!documentToDelete}
                    onClose={() => setDocumentToDelete(null)}
                    document={documentToDelete}
                    handleDocumentDelete={handleDeleteDocument}
                />
            )}
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        {/* <TableCell>Category</TableCell>
                        <TableCell>Description</TableCell> */}
                        <TableCell>Created</TableCell>
                        <TableCell>Modified</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {documents?.map((document, index) => (
                        <TableRow key={index}>
                            <TableCell>
                                {isDeletingDocument ? (
                                    document.displayName === "" ? (
                                        document?.name
                                    ) : (
                                        document?.displayName
                                    )
                                ) : (
                                    <a href={document?.url} target="_blank" rel="noopener noreferrer">
                                        {!document?.displayName ? document?.name : document?.displayName}
                                    </a>
                                )}
                            </TableCell>
                            {/* <TableCell>{document.category}</TableCell>
                            <TableCell>{document.description}</TableCell> */}
                            <TableCell>
                                <RelativeTime timestamp={document.createdOn} />
                            </TableCell>
                            <TableCell>
                                <RelativeTime timestamp={document.lastModified} />
                            </TableCell>

                            <TableCell>
                                {isDeletingDocument ? (
                                    <CircularProgress size="small" />
                                ) : (
                                    <Stack direction="row" spacing={1}>
                                        <IconButton size="small" onClick={() => handleStartEditDocument(document)}>
                                            <Edit />
                                        </IconButton>

                                        <IconButton size="small" onClick={() => handleShowDeleteConfirmDialog(document)}>
                                            <Delete />
                                        </IconButton>
                                    </Stack>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
};

const DocumentUpdateDialog = ({ open, onClose, document, handleDocumentUpload }) => {
    const [fileDisplayName, setFileDisplayName] = useState(null);
    const [fileDescription, setFileDescription] = useState(null);
    const [fileCategory, setFileCategory] = useState("SOP");
    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogTitle>Edit Document</DialogTitle>
            {document && (
                <DialogContent>
                    <Grid container spacing={2} paddingTop={1}>
                        <Grid item xs={12}>
                            <TextField label="File Name" value={document.name} disabled size="small" fullWidth />
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                label="Category"
                                value={fileCategory}
                                onChange={(e) => setFileCategory(e.target.value)}
                                size="small"
                                fullWidth
                            >
                                <MenuItem value="SOP">Standard Operating Procedure</MenuItem>
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Display Name"
                                value={fileDisplayName ?? document.displayName}
                                onChange={(e) => setFileDisplayName(e.target.value)}
                                size="small"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Description"
                                value={fileDescription}
                                onChange={(e) => setFileDescription(e.target.value)}
                                size="small"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} justifyContent="flex-end">
                            <Button
                                variant="contained"
                                startIcon={<CloudUpload />}
                                onClick={() =>
                                    handleDocumentUpload({
                                        category: fileCategory,
                                        description: fileDescription,
                                        displayName: fileDisplayName,
                                    })
                                }
                            >
                                Upload File
                            </Button>
                        </Grid>
                    </Grid>
                </DialogContent>
            )}
        </Dialog>
    );
};

const ConfirmDocumentDeleteDialog = ({ open, onClose, document, handleDocumentDelete }) => {
    const handleDeleteClick = () => {
        handleDocumentDelete(document?.name);
        onClose();
    };
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Delete {document?.name}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Document will be permanently deleted. Are you sure you want to delete this document?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleDeleteClick} autoFocus>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});
