import { Grid, Typography } from "@mui/material";
import { PaperItem } from "./PaperItem";

export const EntityTableContainer = ({ title, table }) => (
    <Grid container>
        <PaperItem>
            <Grid item xs={12} marginBottom={2}>
                <Typography variant="h4">{title}</Typography>
            </Grid>
            <Grid item xs={12}>
                {table}
            </Grid>
        </PaperItem>
    </Grid>
);
