import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";


export function displayLocalTimestamp(timestamp: string) {
    dayjs.extend(utc);
    dayjs.extend(timezone);

    return dayjs.utc(timestamp).tz(dayjs.tz.guess());
}