import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useIsMemberOfRequiredGroup } from "../../hooks/auth/useIsMemberOfRequiredGroup";
import { Grid } from "@mui/material";

export const PrivateRoute = ({ groupId }) => {
    const isMember = useIsMemberOfRequiredGroup(groupId);

    return isMember ? (
        <Outlet />
    ) : (
        <Grid container>
            <Grid item xs={12}>
                <h3>Not Authorized</h3>
            </Grid>
        </Grid>
    );
};
