import { Table as MuiTable, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import { Row, RowData, Table, flexRender } from "@tanstack/react-table";
import { Dispatch, ReactElement, useEffect, useState } from "react";

declare module "@tanstack/react-table" {
    interface ColumnMeta<TData extends RowData, TValue> {
        editCell?: (value: TData, setValue: Dispatch<React.SetStateAction<TData>>) => ReactElement | null;
    }
}

export const EditableTable = <T,>({
    table,
    addRowState,
    editRowState,
}: {
    table: Table<T>;
    addRowState: any;
    editRowState: any;
}) => {
    useEffect(() => console.log({ editRowState, rows: table.getRowModel().rows }), [editRowState, table]);
    return (
        <MuiTable>
            <TableHead>
                {table.getHeaderGroups().map((headerGroup) => (
                    <TableRow key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                            <TableCell key={header.id}>
                                {header.isPlaceholder
                                    ? null
                                    : flexRender(header.column.columnDef.header, header.getContext())}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableHead>
            <TableBody>
                {!!addRowState && <EditRow table={table} rowState={addRowState} />}
                {table.getRowModel().rows.map((row) =>
                    editRowState?.id === row.id ? (
                        <EditRow key={editRowState.id} table={table} rowState={editRowState} rowData={row} />
                    ) : (
                        <TableRow key={row.id}>
                            {row.getVisibleCells().map((cell) => (
                                <TableCell key={cell.id} width={cell.column.getSize()}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </TableCell>
                            ))}
                        </TableRow>
                    )
                )}
            </TableBody>
        </MuiTable>
    );
};

export const EditRow = <T,>({
    table,
    rowState,
    rowData = null,
}: {
    table: Table<T>;
    rowState: T;
    rowData?: Row<T>;
}) => {
    const [row, setRow] = useState(rowState);
    const visibleColumns = table.getVisibleFlatColumns();
    return rowData ? (
        <TableRow key={rowData.id}>
            {rowData.getVisibleCells().map((cell) => (
                <TableCell key={cell.id} width={cell.column.getSize()}>
                    {cell.column.columnDef.meta?.editCell(row, setRow) ??
                        flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
            ))}
        </TableRow>
    ) : (
        <TableRow>
            {visibleColumns.map((column, index) =>
                column.getIsVisible() ? (
                    <TableCell key={index} sx={{ maxWidth: column.getSize() }} width={column.getSize()}>
                        {column.columnDef.meta?.editCell(row, setRow)}
                    </TableCell>
                ) : null
            )}
        </TableRow>
    );
};
