import { Tooltip, Typography, TypographyPropsVariantOverrides } from "@mui/material";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import moment from "moment";

export function formatTimestamp(timestamp: Date) {
    dayjs.extend(localizedFormat);
    return dayjs(timestamp).format("LLLL");
}

type RelativeTimeProps = {
    timestamp: Date;
    placement?:
        | "left-start"
        | "bottom-end"
        | "bottom-start"
        | "bottom"
        | "left-end"
        | "left"
        | "right-end"
        | "right-start"
        | "right"
        | "top-end"
        | "top-start"
        | "top";
};
export const RelativeTime = ({ timestamp, placement = "left-start" }: RelativeTimeProps) => {
    const relativeTime = moment(timestamp).fromNow();
    return timestamp ? (
        <Tooltip title={formatTimestamp(timestamp)} placement={placement}>
            <>{relativeTime}</>
        </Tooltip>
    ) : (
        <Typography>Never</Typography>
    );
};
