import { Button, Card, CardContent, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useCurrentSite } from "../../hooks/general/useCurrentSite";
import { PaperItem } from "../../utilities/PaperItem";

export const QualityControlHome = () => {
    const { site, isLoading } = useCurrentSite();
    const navigate = useNavigate();

    return (
        <Grid container>
            <PaperItem>
                <Grid item xs={12} marginBottom={2}>
                    {isLoading || !site ? (
                        <CircularProgress />
                    ) : (
                        <Typography variant="h4">{toTitleCase(site.name)} Home</Typography>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Grid container>
                        {isLoading || !site ? (
                            <CircularProgress />
                        ) : (
                            <Grid item xs={12}>
                                <Stack alignItems="flex-start">
                                    <Button onClick={() => navigate(`/qc/${site.code}/overview`)}>Overview</Button>
                                    <Button onClick={() => navigate(`/qc/${site.code}/samples`)}>
                                        Samples Database
                                    </Button>
                                    <Button onClick={() => navigate(`/qc/${site.code}/samples?statuses=0&statuses=1`)}>
                                        Edit Sample
                                    </Button>
                                    <Button onClick={() => navigate(`/qc/${site.code}/add-sample`)}>
                                        Add Sample
                                    </Button>
                                    <Button onClick={() => navigate(`/admin/sieve-sets`)}>Sieve Management</Button>
                                    <Button onClick={() => navigate(`/qc/${site.code}/documents`)}>Documents</Button>
                                </Stack>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </PaperItem>
        </Grid>
    );
};
function toTitleCase(str) {
    return str.replace(/\w\S*/g, (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase());
}
export default QualityControlHome;
