import { Autocomplete, CircularProgress, TextField } from "@mui/material";

type AsyncSelectProps = {
    label: string;
    options: any;
    handleChange: any;
    selectedOptions: any;
    isLoading: boolean;
    limitTags?: number;
    disabled?: boolean;
    isMulti?: boolean;
    size?: string;
};

export const AsyncSelect = ({
    label,
    options,
    handleChange,
    selectedOptions,
    isLoading,
    disabled = false,
    isMulti = false,
    limitTags = 3,
    size = "medium",
}: AsyncSelectProps) => {
    return (
        <Autocomplete
            limitTags={limitTags}
            multiple={isMulti}
            options={options ?? []}
            getOptionKey={(option) => option?.value}
            getOptionLabel={(option) => option?.label ?? ""}
            onChange={(event, newValue) => {
                handleChange(newValue);
            }}
            isOptionEqualToValue={(option, value) => {
                return option?.value === value?.value;
            }}
            value={selectedOptions}
            renderInput={(params) => <TextField {...params} variant="outlined" label={label} fullWidth />}
            loading={isLoading}
            loadingText={<CircularProgress size={24} />}
            disabled={disabled || isLoading}
            size={size === "medium" || size === "small" ? size : "medium"}
            fullWidth
        />
    );
};
