import { FocusEventHandler } from "react";

export const handleInputFocus: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> = (
	event
) => {
	event.target.select();
	//return event.target.addEventListener(
	//	"wheel",
	//	(event) => {
	//		event.preventDefault();
	//	},
	//	{ passive: false }
	//);
};
