import { useCallback } from "react";
import { RangeSetting, SieveAnalysis } from "../../../types/QualityControlModels";

export const useWeightLostInWashCalculation = () =>
    useCallback(({ postWashDryWeight, preWashDryWeight, oversizeWeight }) => {
        return formatValue(Number(preWashDryWeight || 0) - Number(postWashDryWeight || 0) - Number(oversizeWeight || 0));
    }, []);

export const usePercentLostInWashCalculation = () =>
    useCallback(({ postWashDryWeight, preWashDryWeight, oversizePercent }) => {
        const oversize = Number(oversizePercent || 0) / 100;
        const washedSand = (Number(postWashDryWeight) / Number(preWashDryWeight));
        const calculation = 1 - washedSand - oversize;
        return formatValue(calculation, true);
    }, []);

export const useOversizeAndSlimesCalculation = () =>
    useCallback(({ gramsLostInWash, oversizeWeight }) => {
        const calculation = Number(gramsLostInWash) + Number(oversizeWeight);
        return formatValue(calculation);
    }, []);

export const useMoistureRateCalculation = () =>
    useCallback(({ preWashDryWeight, beginningWetWeight }) => {
        const calculation = 1 - Number(preWashDryWeight) / Number(beginningWetWeight);
        return formatValue(calculation, true);
    }, []);

export const useOversizePercentCalculation = () =>
    useCallback(({ oversizeWeight, preWashDryWeight }) => {
        const calculation = Number(oversizeWeight) / Number(preWashDryWeight);
        return formatValue(calculation, true);
    }, []);

export const useSieveFinalWeightCalculation = () =>
    useCallback(({ startWeight, endWeight }) => {
        const calculation = Number(endWeight) - Number(startWeight);
        return formatValue(calculation);
    }, []);

export const usePanFinalWeightCalculation = () =>
    useCallback(({ startWeight, endWeight, weightLostInWash }) => {
        const calculation = Number(endWeight) - Number(startWeight) + Number(weightLostInWash);
        return formatValue(calculation);
    }, []);

export const useSievePercentFinalCalculation = () =>
    useCallback(({ sieveFinalWeight, sievesTotalWeight, orePercent, isPan = false, percentLostInWash = 0 }) => {
        const calculation = (Number(sieveFinalWeight) / Number(sievesTotalWeight)) * orePercent;
        return isPan ? formatValue(calculation + percentLostInWash) : formatValue(calculation);
    }, []);

export const useSieveTotalWeightAggregation = () =>
    useCallback(({ analyses }) => {
        let totalWeight = 0;

        analyses.forEach((analysis: SieveAnalysis) => {
            const finalWeight = analysis.finalWeight;

            if (isValidNumber(finalWeight) && finalWeight !== 0) {
                totalWeight += Number(finalWeight);
            }
        });

        return formatValue(totalWeight);
    }, []);

export const useSplitSampleDeltaCalculation = () => {
    return useCallback(({ totalWeight, splitSampleWeight }) => {
        const calculation = Number(totalWeight) - Number(splitSampleWeight);
        return formatValue(calculation);
    }, []);
};

export const useSieveRangeAggregation = () =>
    useCallback(({ analyses, rangeSetting }: { analyses: SieveAnalysis[]; rangeSetting: RangeSetting }) => {
        if (!analyses || !analyses.length) {
            return "";
        }

        let rangePercent = 0;

        if (!rangeSetting.startSieve && !rangeSetting.endSieve) {
            // nothing to filter on, sum all sieve analysis percentFinals
            for (let i = 0; i < analyses.length; i++) {
                const percentFinal = analyses[i].percentFinal;
                if (isValidNumber(percentFinal)) {
                    rangePercent += Number(percentFinal);
                }
            }
        } else {
            for (let i = 0; i < analyses.length; i++) {
                const sieveAnalysis = analyses[i];
                const isInRange =
                    (!rangeSetting.startSieve ||
                        (rangeSetting.isStartInclusive
                            ? Number(sieveAnalysis?.sieve?.size) >= rangeSetting.startSieve
                            : Number(sieveAnalysis?.sieve?.size) > rangeSetting.startSieve)) &&
                    (!rangeSetting.endSieve || Number(sieveAnalysis.sieve?.size) <= Number(rangeSetting.endSieve));

                if (isInRange || (!rangeSetting.endSieve && sieveAnalysis.sieve?.isPan)) {
                    const percentFinal = sieveAnalysis?.percentFinal;

                    if (isValidNumber(percentFinal)) {
                        rangePercent += Number(percentFinal);
                    }
                }
            }
        }

        return formatValue(rangePercent);
    }, []);

export const useOrePercentCalculation = () =>
    useCallback(({ postWashDryWeight, preWashDryWeight }) => {
        if (isValidNumber(postWashDryWeight) && isValidNumber(preWashDryWeight)) {
            const calculation = Number(postWashDryWeight) / Number(preWashDryWeight);
            return formatValue(calculation, true);
        }
        return formatValue(1, true);
    }, []);

export const useKValuePercentCalculation = () =>
    useCallback(({ pan }) => {
        const calculation = pan / 40;
        return formatValue(calculation, true);
    }, []);

export const useKValuePanAveragePercentCalculation = () =>
    useCallback(({ kValuePan1Percent, kValuePan2Percent, kValuePan3Percent }) => {
        const calculation = (Number(kValuePan1Percent) + Number(kValuePan2Percent) + Number(kValuePan3Percent)) / 3;
        return formatValue(calculation);
    }, []);

export const useIsCrushTestPassCheck = () =>
    useCallback(({ averageKPanPercent }) => {
        return Number(averageKPanPercent) < 10;
    }, []);

export const useSiltPercentCalculation = () =>
    useCallback(({ siltHeight, sandHeight }) => {
        if (sandHeight > 0) {
            const calculation = Number(siltHeight) / Number(sandHeight);
            return formatValue(calculation, true);
        }
        return 0;
    }, []);

const formatValue = (value: number, formatAsPercent = false, decimalPlaces = 2) => {
    if (!isNaN(value)) {
        return formatAsPercent
            ? parseFloat((Number(value) * 100).toFixed(decimalPlaces))
            : parseFloat(Number(value).toFixed(decimalPlaces));
    }
};

export const useSieveAnalysisCalculationHooks = () => {
    const calculateWeightLostInWash = useWeightLostInWashCalculation();
    const calculatePercentLostInWash = usePercentLostInWashCalculation();
    const calculateOversizeAndSlimes = useOversizeAndSlimesCalculation();
    const calculateMoistureRate = useMoistureRateCalculation();
    const calculateOversizePercent = useOversizePercentCalculation();
    const calculateSieveFinalWeight = useSieveFinalWeightCalculation();
    const calculateSievePercentFinal = useSievePercentFinalCalculation();
    const calculateOrePercent = useOrePercentCalculation();
    const calculateTotalWeight = useSieveTotalWeightAggregation();
    const calculateSieveRange = useSieveRangeAggregation();
    const calculatePanFinalWeight = usePanFinalWeightCalculation();
    const calculateSplitSampleDelta = useSplitSampleDeltaCalculation();
    return {
        calculateWeightLostInWash,
        calculatePercentLostInWash,
        calculateOversizeAndSlimes,
        calculateMoistureRate,
        calculateOversizePercent,
        calculateSieveFinalWeight,
        calculateSievePercentFinal,
        calculateOrePercent,
        calculateTotalWeight,
        calculateSieveRange,
        calculatePanFinalWeight,
        calculateSplitSampleDelta,
    };
};

export const isValidNumber = (value) => {
    return !isNaN(value) && typeof value !== "undefined" && value !== null;
};
