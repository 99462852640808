import { Chip, CircularProgress, Typography } from "@mui/material";
import { useMemo } from "react";
import { useGetRepeatabilityCountByUser } from "../../../queries/QualityControlQueries";
import { useMsal } from "@azure/msal-react";

// REPEATABILITY counter is showing the number of samples the lab tech has completed since they last repeated a sample
export const RepeatabilityCounter = () => {
    const { accounts } = useMsal();
    const { data: samplesSinceLastRepeat, isLoading: isRepeatabilityCountLoading } = useGetRepeatabilityCountByUser(
        accounts[0]?.username
    );
    // Check user is a lab tech
    // Get repeatability by user id
    const isRepeatNeeded = samplesSinceLastRepeat > 99;
    const label = useMemo(
        () => (
            <Typography>
                Repeatability: {isRepeatabilityCountLoading ? <CircularProgress size={14} /> : samplesSinceLastRepeat}
            </Typography>
        ),
        [samplesSinceLastRepeat]
    );
    return <Chip label={label} color={isRepeatNeeded ? "error" : "default"} />;
};
