import { EntityTableContainer } from "../../utilities/EntityTableContainer";
import { EntityTable } from "../../utilities/EntityTable";
import { useAddOrUpdateSiteMutation, useGetSites } from "../../queries/AdminQueries";
import { render } from "@testing-library/react";
import { Button, Typography } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid";

export const ManageSites = () => {
    const { data: sites, isLoading } = useGetSites();
    const columnFields: GridColumns = [
        { field: "id", headerName: "ID", flex: 2, editable: false },
        { field: "name", headerName: "Name", flex: 2, editable: true },
        { field: "code", headerName: "Code", flex: 2, editable: true },
        { field: "description", headerName: "Description", flex: 2, editable: true },
        // {
        //     field: "rangeSettingsGroup",
        //     headerName: "Sieve Groups",
        //     renderCell: ({ row }) => <Typography fontSize={14}>{`${row.rangeSettingsGroup?.length} groupings`}</Typography>,
        //     flex: 2,
        //     editable: false,
        // },
        {
            field: "isActive",
            headerName: "Visible",
            flex: 2,
            type: "boolean",
            editable: true,
        },
    ];

    return (
        <>
            {sites && (
                <EntityTableContainer
                    title="Sites"
                    table={
                        <EntityTable
                            entity={sites}
                            columnFields={columnFields}
                            useAddOrUpdateEntity={useAddOrUpdateSiteMutation}
                            isLoading={isLoading}
                        />
                    }
                />
            )}
        </>
    );
};
