import { Paper } from "@mui/material";

export const PaperItem = ({ rounded = true, outlined = true, children }) => {
    return (
        <Paper
            sx={(theme) => ({
                p: 2,
                margin: "auto",
                flexGrow: 1,
                borderRadius: rounded ? theme.shape.borderRadius : 0,
            })}
            variant={outlined ? "outlined" : "elevation"}
        >
            {children}
        </Paper>
    );
};
