import {
    useAddOrUpdateRangeSetting,
    useAddSieveGroup,
    useGetRangeSettings,
    useGetSieveGroups,
    useGetSieveSets,
} from "../../queries/QualityControlQueries";
import { useGetSites } from "../../queries/AdminQueries";
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { SieveGroup, Site, RangeSettingGroup, RangeSetting, Sieve } from "../../types/QualityControlModels";
import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { Add, Cancel, Edit, Save } from "@mui/icons-material";
import { useCallback, useEffect, useMemo, useState } from "react";
import "../../utilities/ReactTableComponents";
import { EditRow, EditableTable } from "../../utilities/ReactTableComponents";
import { toast } from "react-toastify";
import { EntityTableContainer } from "../../utilities/EntityTableContainer";

export const ManageSieveGroups = () => {
    //const { data: sites, isLoading: isLoadingSites } = useGetSites();
    const { data: sieveSets, isLoading: isLoadingSieveSets } = useGetSieveSets();
    const { data: sieveGroups, isLoading } = useGetRangeSettings();
    const [addRow, setAddRow] = useState<RangeSetting>(null);
    const [editRow, setEditRow] = useState<RangeSetting>(null);

    const showDelete = useMemo(() => false, []);

    const { mutate: addOrUpdateRangeSetting, isLoading: isAddingOrUpdatingRangeSetting } = useAddOrUpdateRangeSetting();

    const columnHelper = createColumnHelper<RangeSetting>();

    const resetEditMode = () => {
        setAddRow(null);
        setEditRow(null);
    };

    const handleSaveChanges = useCallback(
        (state: RangeSetting) => {
            addOrUpdateRangeSetting(state, {
                onSuccess: () => {
                    toast.success(`Grouping ${state.name} updated successfully!`);
                    resetEditMode();
                },
            });
        },
        [addOrUpdateRangeSetting]
    );

    const canSave = (rangeSetting: RangeSetting) => {
        return (
            !!rangeSetting.name &&
            !!rangeSetting.isStartInclusive?.toString() &&
            !!rangeSetting.startSieve &&
            !!rangeSetting.displayOrder
        );
    };

    const allSieveSizes = useMemo(() => {
        if (sieveSets) {
            const sieves = sieveSets.flatMap((sieveSet) => sieveSet.sieves);
            const uniqueSizes = new Set(sieves.map((sieve) => sieve.size));
            uniqueSizes.add("10"); // Add the value 10 to the set
            const sortedSizes = [...uniqueSizes].sort((a, b) => {
                if (a === "PAN") return 1;
                if (b === "PAN") return -1;
                return Number(a) - Number(b);
            });
            return sortedSizes;
        }
        return [];
    }, [sieveSets]);
    const columns = [
        columnHelper.accessor("name", {
            header: "Display Name",
            size: 150,
            meta: {
                editCell: (state, setState) => (
                    <TextField
                        size="small"
                        value={state.name || ""}
                        onChange={(e) => setState((prev) => ({ ...prev, name: e.target.value }))}
                    />
                ),
            },
        }),
        columnHelper.accessor("startSieve", {
            header: "Start",
            size: 150,
            cell: (context) => `${context.row.original.isStartInclusive ? "+" : "-"}${context.getValue()}`,
            meta: {
                editCell: (state, setState) => (
                    <>
                        <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                            <Select
                                value={state.isStartInclusive || "true"}
                                onChange={(e) => setState((prev) => ({ ...prev, isStartInclusive: e.target.value === "true" }))}
                                size="small"
                            >
                                <MenuItem key={0} value={"false"}>-</MenuItem>
                                <MenuItem key={1} value={"true"}>+</MenuItem>
                            </Select>
                            <Select
                                value={state.startSieve || 10}
                                onChange={(e) => setState((prev) => ({ ...prev, startSieve: Number(e.target.value) }))}
                                size="small"
                            >
                                {allSieveSizes.map((sieveSize: number, index: number) => (
                                    <MenuItem key={index} value={sieveSize}>{sieveSize}</MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </>
                ),
            },
        }),
        columnHelper.accessor("endSieve", {
            header: "End",
            size: 200,
            cell: (context) => (context.getValue() ? `+${context.getValue()}` : "PAN"),

            meta: {
                editCell: (state, setState) => (
                    <Select
                        value={state.endSieve || "PAN"}
                        onChange={(e) =>
                            setState((prev) => ({
                                ...prev,
                                endSieve: e.target.value === "PAN" ? "PAN" : Number(e.target.value),
                            }))
                        }
                        size="small"
                    >
                        {allSieveSizes
                            .filter(
                                (size: number) =>
                                    size.toString() === "PAN" ||
                                    Number(size) === 10 ||
                                    Number(size) > Number(state?.startSieve)
                            )
                            .map((sieveSize: number, index: number) => (
                                <MenuItem key={index} value={sieveSize}>{sieveSize}</MenuItem>
                            ))}
                    </Select>
                ),
            },
        }),
        columnHelper.accessor("displayOrder", {
            header: "Display Order",
            size: 150,
            meta: {
                editCell: (state, setState) => (
                    <TextField
                        size="small"
                        value={state.displayOrder || ""}
                        onChange={(e) => setState((prev) => ({ ...prev, displayOrder: Number(e.target.value) }))}
                    />
                ),
            },
        }),
        columnHelper.display({
            header: "Actions",
            cell: ({ row }) => (
                <IconButton size="small" onClick={() => setEditRow(row.original)}>
                    <Edit />
                </IconButton>
            ),
            meta: {
                editCell: (state, _) => (
                    <>
                        <IconButton
                            title="Save Changes"
                            size="small"
                            onClick={() => handleSaveChanges(state)}
                            disabled={!canSave(state)}
                        >
                            {isAddingOrUpdatingRangeSetting ? <CircularProgress size={24} /> : <Save />}
                        </IconButton>

                        <IconButton title="Cancel" size="small" onClick={resetEditMode}>
                            <Cancel />
                        </IconButton>
                    </>
                ),
            },
        }),
    ];
    const table = useReactTable({
        columns,
        data: sieveGroups,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <Box
            sx={{
                height: "75vh",
                width: "100%",
                "& .actions": {
                    color: "text.secondary",
                },
                "& .textPrimary": {
                    color: "text.primary",
                },
            }}
        >
            {sieveGroups && (
                <EntityTableContainer
                    title="Sieve Groups"
                    table={
                        <>
                            <Box
                                sx={(theme) => ({
                                    backgroundColor: theme.palette.primary.main,
                                    paddingTop: "24px",
                                    paddingBottom: "24px",
                                    borderTopLeftRadius: "6px",
                                    borderTopRightRadius: "6px",
                                    width: "100%",
                                })}
                            >
                                <Button
                                    variant="text"
                                    startIcon={<Add />}
                                    sx={(theme) => ({
                                        color: theme.palette.getContrastText(theme.palette.primary.main),
                                    })}
                                    size="small"
                                    onClick={() =>
                                        setAddRow({
                                            id: 0,
                                            startSieve: null,
                                            endSieve: null,
                                            isStartInclusive: null,
                                            name: "",
                                            displayOrder: 0,
                                        })
                                    }
                                >
                                    Add Group
                                </Button>
                            </Box>
                            <TableContainer sx={{ height: "58vh" }}>
                                <Table stickyHeader>
                                    <TableHead>
                                        {table.getHeaderGroups().map((headerGroup) => (
                                            <TableRow key={headerGroup.id}>
                                                {headerGroup.headers.map((header) => (
                                                    <TableCell key={header.id}>
                                                        {header.isPlaceholder
                                                            ? null
                                                            : flexRender(
                                                                  header.column.columnDef.header,
                                                                  header.getContext()
                                                              )}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableHead>
                                    <TableBody>
                                        {!!addRow && <EditRow table={table} rowState={addRow} />}
                                        {table
                                            .getRowModel()
                                            .rows?.sort((a, b) => a.original.displayOrder - b.original.displayOrder)
                                            .map((row) =>
                                                editRow?.id === row.original?.id ? (
                                                    <EditRow
                                                        key={row.id}
                                                        table={table}
                                                        rowState={editRow}
                                                        rowData={row}
                                                    />
                                                ) : (
                                                    <TableRow key={row.id}>
                                                        {row.getVisibleCells().map((cell) => (
                                                            <TableCell key={cell.id} width={cell.column.getSize()}>
                                                                {flexRender(
                                                                    cell.column.columnDef.cell,
                                                                    cell.getContext()
                                                                )}
                                                            </TableCell>
                                                        ))}
                                                    </TableRow>
                                                )
                                            )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    }
                />
            )}
        </Box>
    );
};
