import { CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";
import { CircularProgress, Autocomplete, Checkbox, TextField } from "@mui/material";

export const CheckBoxSelect = ({ data, isLoadingData, selected, handleSelectionChange }) => {
    if (isLoadingData) return <CircularProgress size="small" />;
    return (
        <Autocomplete
            multiple
            options={data}
            disableCloseOnSelect
            value={selected}
            isOptionEqualToValue={(option, value) => {
                return option.id === value?.id;
            }}
            onChange={(event, newValue) => handleSelectionChange(newValue)}
            getOptionLabel={(option) => option?.name}
            renderOption={(props, option, { selected }) => {
                const { key, ...rest} = props as any;
                return (
                    <li key={key} {...rest}>
                        <Checkbox
                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                            checkedIcon={<CheckBox fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option.name}
                    </li>
                );
            }}
            loading={isLoadingData}
            renderInput={(params) => <TextField {...params} />}
            size="small"
            sx={{ "& :hover .MuiAutocomplete-input, & .Mui-focused .MuiAutocomplete-input": { minWidth: "30px" } }}
        />
    );
};
