export enum UserGroup {
    QualityControl = "Quality Control",
}

export enum UserRole {
    Sampler = "Sampler",
    LabTech = "Lab Tech",
    Operator = "Operator",
}

export enum SampleStatus {
    New,
    InProgress,
    Complete,
    Voided,
    Error,
}

export function SampleStatusToString(sampleStatus: SampleStatus) {
    switch (sampleStatus) {
        case 0:
            return "New";
        case 1:
            return "In Progress";
        case 2:
            return "Complete";
        case 3:
            return "Voided";
        case 4:
            return "Error";
        default:
            return "Not Found";
    }
}
