import LoadingButton from "@mui/lab/LoadingButton";

type AsyncButtonPropTypes = {
    text: string;
    onClick: (e) => void;
    isLoading: boolean;
    disabled?: boolean;
    isFullWidth?: boolean;
    color?: "inherit" | "primary" | "info" | "secondary" | "error" | "success" | "warning";
};

export const AsyncButton = ({
    text,
    onClick,
    isLoading,
    disabled,
    isFullWidth = false,
    color = "primary",
}: AsyncButtonPropTypes) => {
    return (
        <LoadingButton
            disableElevation
            variant="contained"
            fullWidth={isFullWidth}
            onClick={onClick}
            loading={isLoading}
            disabled={disabled || isLoading}
            color={color}
        >
            {text}
        </LoadingButton>
    );
};
