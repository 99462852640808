import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { useUserInfo } from "../../hooks/general/useUserInfo";

export const Profile = () => {
    const { userInfo, isLoading } = useUserInfo();
 
    if (isLoading) return <div>Loading...</div>;

    return (
        <Card>
            <CardHeader title={<Typography variant="h5">Profile</Typography>}></CardHeader>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Name</Typography>
                        <Typography>{userInfo.displayName}</Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
