import { useParams } from "react-router-dom";
import { useGetSites } from "../../queries/AdminQueries";
import { useEffect, useState } from "react";
import { Site } from "../../types/QualityControlModels";

export const useCurrentSite = () => {
    const params = useParams();
    const { data: sites, isLoading: isLoadingSites } = useGetSites();
    const [ site, setSite ] = useState<Site>(null);

    useEffect(() => {
        if (params.siteCode && sites) {
            setSite(sites.find((site) => site.code === params.siteCode));
        }
    }, [params, sites]);

    return { site, isLoading: isLoadingSites };
};
