import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useState, useEffect } from "react";

export const useIsMemberOfRequiredGroup = (groupId) => {
    const { instance, accounts } = useMsal();
    const [isMember, setIsMember] = useState(false);

    useEffect(() => {
        const checkGroupMembership = async () => {
            if (accounts.length > 0) {
                const account = accounts[0];
                try {
                    const response = await instance.acquireTokenSilent({
                        scopes: ["User.Read"],
                        account: account,
                    });

                    const idToken: any = response.idTokenClaims;
                    const groups = idToken.groups || [];

                    setIsMember(groups.includes(groupId));
                } catch (error) {
                    if (error instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenRedirect({
                            scopes: ["User.Read"],
                        });
                    } else {
                        console.error(error);
                    }
                }
            }
        };

        checkGroupMembership();
    }, [instance, accounts]);

    return isMember;
};
