import { Navigation } from "./shared/components/general/Navigation";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { BreadcrumbsDisplay } from "./shared/components/general/Breadcrumbs";
import { Grid } from "@mui/material";
import { Container } from "@mui/system";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { routes } from "./shared/Routes";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useIsAuthenticated } from "@azure/msal-react";
import { PrivateRoute } from "./shared/components/auth/PrivateRoute";

export const App = () => {
    const isAuthenticated = useIsAuthenticated();
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                onError: (error: Error) => {
                    toast.error(error.message);
                },
            },
            mutations: {
                onError: (error: Error) => {
                    toast.error(error.message);
                },
            },
        },
    });

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <QueryClientProvider client={queryClient}>
                <div className="App">
                    <Router>
                        <Navigation />
                        {isAuthenticated && (
                            <Container maxWidth="xl">
                                <Grid container spacing={2}>
                                    <Grid item xs={12} />
                                    <Grid item xs={12}>
                                        <BreadcrumbsDisplay />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Routes>
                                            {routes.map((route, key) =>
                                                route.isPrivate ? (
                                                    <Route
                                                        element={<PrivateRoute groupId={route?.groupId} />}
                                                        key={key}
                                                        path={route.path}
                                                    >
                                                        <Route path={route.path} key={key} element={route.element} />
                                                    </Route>
                                                ) : (
                                                    <Route path={route.path} key={key} element={route.element} />
                                                )
                                            )}
                                        </Routes>
                                    </Grid>
                                </Grid>
                            </Container>
                        )}
                    </Router>
                </div>
                <ToastContainer />
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
        </LocalizationProvider>
    );
};

export default App;
