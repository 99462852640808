import { useAddSieveGroup, useDeleteSieveGroupSiteMapping, useGetRangeSettings, useGetSieveGroups } from "../../queries/QualityControlQueries";
import { useGetSites } from "../../queries/AdminQueries";
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { Site, RangeSettingGroup, RangeSetting } from "../../types/QualityControlModels";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Add, Cancel, Delete, Edit, Save } from "@mui/icons-material";
import React, { useMemo, useState } from "react";
import "../../utilities/ReactTableComponents";
import { EditRow } from "../../utilities/ReactTableComponents";
import { EntityTableContainer } from "../../utilities/EntityTableContainer";
import { CheckBoxSelect } from "../general/CheckBoxSelect";
import { toast } from "react-toastify";

export const ManageSiteSieveGroupMaps = () => {
    const { data: sites, isLoading: isLoadingSites } = useGetSites();
    const { data: rangeSettings, isLoading: isLoadingRangeSettings } = useGetRangeSettings();
    const { data: sieveGroups, isLoading } = useGetSieveGroups();
    const [addRow, setAddRow] = useState(null);
    const [editRow, setEditRow] = useState(null);
    const [deleteRow, setDeleteRow] = useState(null);
    const showDelete = useMemo(() => false, []);
    const { mutate: addSieveGroup, isLoading: isAddingSieveGroup } = useAddSieveGroup();
    const { mutate: deleteSieveGroup, isLoading: isDeletingSieveGroup } = useDeleteSieveGroupSiteMapping();
    const columnHelper = createColumnHelper<RangeSettingGroup>();

    const clearEditAndAddRows = () => {
        setAddRow(null);
        setEditRow(null);
        setDeleteRow(null);
    };
    const handleShowDelete = (row) => {
        setDeleteRow(row);
    };
    const handleDelete = () => {
        deleteSieveGroup(deleteRow.id, {
            onSuccess: () => {
                toast.success("Sieve Group Site Mapping deleted successfully!");
                clearEditAndAddRows();
            },
        });
    };
    const columns = [
        columnHelper.accessor("id", {
            header: "ID",
            size: 100,
        }),
        columnHelper.accessor("siteRangeSettingGroups", {
            header: "Sites",
            size: 150,
            cell: (context) =>
                context
                    .getValue()
                    ?.map(({ site }) => site.name)
                    .join(", "),
            meta: {
                editCell: (state, setState) => {
                    return (
                        <CheckBoxSelect
                            data={sites}
                            isLoadingData={isLoadingSites}
                            selected={state?.siteRangeSettingGroups?.map((siteRangeSettingGroup) => siteRangeSettingGroup.site) ?? []}
                            handleSelectionChange={(selectedSites: Site[]) => {
                                setState((prev) => ({
                                    ...prev,
                                    siteRangeSettingGroups: [...selectedSites.map((site) => ({ site, siteId: Number(site.id) }))],
                                }));
                            }}
                        />
                    );
                },
            },
        }),
        columnHelper.accessor("rangeSettingGroupRangeSettings", {
            header: "Groups",
            size: 200,
            cell: (context) =>
                context
                    .getValue()
                    ?.map((sieveGroup) => sieveGroup.rangeSetting.name)
                    .join(", "),
            meta: {
                editCell: (state, setState) => (
                    <CheckBoxSelect
                        data={rangeSettings}
                        isLoadingData={isLoadingRangeSettings}
                        selected={state?.rangeSettingGroupRangeSettings?.map((group) => group.rangeSetting) ?? []}
                        handleSelectionChange={(selected: RangeSetting[]) =>
                            setState((prev) => ({
                                ...prev,
                                rangeSettingGroupRangeSettings: [
                                    ...selected.map((rangeSetting) => ({
                                        rangeSetting,
                                        rangeSettingId: rangeSetting.id,
                                    })),
                                ],
                            }))
                        }
                    />
                ),
            },
        }),
        columnHelper.display({
            header: "Actions",
            cell: (context) => (
                <>
                    <IconButton
                        size="small"
                        onClick={() => {
                            setEditRow(context.row.original);
                        }}
                    >
                        <Edit />
                    </IconButton>
                    <IconButton size="small" title="Delete" onClick={() => handleShowDelete(context.row.original)}>
                        <Delete />
                    </IconButton>
                </>
            ),
            meta: {
                editCell: (state) => (
                    <>
                        <IconButton
                            size="small"
                            title="Save Changes"
                            onClick={() =>
                                addSieveGroup(state, {
                                    onSuccess: () => {
                                        toast.success("Changes saved successfully!");
                                        clearEditAndAddRows();
                                    },
                                })
                            }
                        >
                            {isAddingSieveGroup ? <CircularProgress size={24} /> : <Save />}
                        </IconButton>
                        <IconButton size="small" title="Cancel Changes" onClick={clearEditAndAddRows}>
                            <Cancel />
                        </IconButton>
                    </>
                ),
            },
        }),
    ];
    const table = useReactTable({
        columns,
        data: sieveGroups,
        getCoreRowModel: getCoreRowModel(),
    });

    return (
        <Box
            sx={{
                height: "75vh",
                width: "100%",
                "& .actions": {
                    color: "text.secondary",
                },
                "& .textPrimary": {
                    color: "text.primary",
                },
            }}
        >
            {deleteRow && (
                <Dialog open onClose={clearEditAndAddRows}>
                    <DialogTitle>Delete Sieve Group Site Mapping #{deleteRow?.id}?</DialogTitle>
                    <DialogContent>
                        <DialogContentText>Sieve Group Site Mapping will be permanently deleted. Are you sure you want to delete this record?</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={clearEditAndAddRows}>Cancel</Button>
                        <Button onClick={handleDelete} autoFocus>
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            {sieveGroups && (
                <EntityTableContainer
                    title="Sieve Groups"
                    table={
                        <>
                            <Box
                                sx={(theme) => ({
                                    backgroundColor: theme.palette.primary.main,
                                    paddingTop: "24px",
                                    paddingBottom: "24px",
                                    borderTopLeftRadius: "6px",
                                    borderTopRightRadius: "6px",
                                    width: "100%",
                                })}
                            >
                                <Button
                                    variant="text"
                                    startIcon={<Add />}
                                    sx={(theme) => ({
                                        color: theme.palette.getContrastText(theme.palette.primary.main),
                                    })}
                                    size="small"
                                    onClick={() =>
                                        setAddRow({
                                            id: 0,
                                            startSieve: null,
                                            endSieve: null,
                                            isStartInclusive: null,
                                            name: "",
                                            displayOrder: 0,
                                        })
                                    }
                                >
                                    Add Group
                                </Button>
                            </Box>
                            <TableContainer sx={{ height: "58vh" }}>
                                <Table stickyHeader>
                                    <TableHead>
                                        {table.getHeaderGroups().map((headerGroup) => (
                                            <TableRow key={headerGroup.id}>
                                                {headerGroup.headers.map((header) => (
                                                    <TableCell key={header.id}>{header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}</TableCell>
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableHead>
                                    <TableBody>
                                        {!!addRow && <EditRow table={table} rowState={addRow} />}
                                        {table.getRowModel().rows.map((row) =>
                                            editRow?.id === row.original?.id ? (
                                                <EditRow key={row.id} table={table} rowState={editRow} rowData={row} />
                                            ) : (
                                                <TableRow key={row.id}>
                                                    {row.getVisibleCells().map((cell) => (
                                                        <TableCell key={cell.id} width={cell.column.getSize()}>
                                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            )
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                    }
                />
            )}
        </Box>
    );
};
