import { CircularProgress, Box, Tooltip } from "@mui/material";
import {
    GridRowParams,
    MuiEvent,
    GridRowModes,
    GridEventListener,
    GridRowId,
    GridRowModel,
    GridRowModesModel,
    GridActionsCellItem,
    DataGrid,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExport,
    useGridApiContext,
} from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import { EntityTableToolbarButton } from "../components/general/EntityTableToolbarButton";

export const EntityTable = ({
    entity,
    isLoading,
    useAddOrUpdateEntity,
    useAddOrUpdateEntityParams = {},
    columnFields,
    showDelete = false,
    customToolbarComponents = [],
    allowAddRecord = true,
    adminActions = []
}) => {
    const [rows, setRows] = useState(null);

    useEffect(() => {
        if (entity) {
            setRows(entity);
        }
    }, [entity, setRows]);

    const [rowMode, setRowMode] = useState({});
    const { mutate: addOrUpdate, isLoading: isAddOrUpdateLoading } = useAddOrUpdateEntity(useAddOrUpdateEntityParams);

    const handleRowEditStart = (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
        const { row } = params;
        setRowMode({ ...rowMode, [row.id]: { mode: GridRowModes.Edit } });
        event.defaultMuiPrevented = true;
    };

    const handleRowEditStop: GridEventListener<"rowEditStop"> = (params, event) => {
        event.defaultMuiPrevented = true;
    };

    const handleEditClick = (id: GridRowId) => () => {
        setRowMode({ ...rowMode, [id]: { mode: GridRowModes.Edit } });
    };

    const handleSaveClick = (id: GridRowId) => () => {
        setRowMode({ ...rowMode, [id]: { mode: GridRowModes.View } });
    };

    const handleDeleteClick = (id: GridRowId) => () => {
        setRows(rows.filter((row) => row.id !== id));
    };

    const handleCancelClick = (id: GridRowId) => () => {
        setRowMode({
            ...rowMode,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });

        const editedRow = rows.find((row) => row.id === id);
        if (editedRow!.isNew) {
            setRows(rows.filter((row) => row.id !== id));
        }
    };

    const processRowUpdate = (newRow: GridRowModel) => {
        //const updatedRow = { ...newRow, isNew: true };
        addOrUpdate(newRow, {
            onSuccess: () => {
                setRows(rows.map((row) => (row.id === newRow.id ? newRow : row)));
            },
        });

        return newRow;
    };

    const handleRowUpdateError = (error: any) => {
        console.error(error);
    };

    const handleRowModeChange = (newRowMode: GridRowModesModel) => {
        setRowMode(newRowMode);
    };

    const columns = [
        ...columnFields,
        {
            //Only show if Admin
            field: "actions",
            type: "actions",
            headerName: "Admin Actions",
            flex: 1.5,
            cellClassName: "actions",
            getActions: (params) => {
                const isInEditMode = rowMode[params.id]?.mode === GridRowModes.Edit;
                if (isInEditMode) {
                    return [
                        <Tooltip title="Save Changes" placement={showDelete ? "left-start" : "right-start"}>
                            <GridActionsCellItem
                                icon={isAddOrUpdateLoading ? <CircularProgress size={24} /> : <SaveIcon />}
                                label="Save"
                                onClick={handleSaveClick(params.id)}
                                placeholder={undefined}
                                onPointerEnterCapture={undefined}
                                onPointerLeaveCapture={undefined}
                            />
                        </Tooltip>,
                        <Tooltip title="Cancel Changes" placement="right-start">
                            <GridActionsCellItem
                                icon={<CancelIcon />}
                                label="Cancel"
                                className="textPrimary"
                                onClick={handleCancelClick(params.id)}
                                color="inherit"
                                placeholder={undefined}
                                onPointerEnterCapture={undefined}
                                onPointerLeaveCapture={undefined}
                            />
                        </Tooltip>,
                    ];
                }

                return [
                    ...adminActions,
                    <Tooltip title="Edit" placement={showDelete ? "left-start" : "right-start"}>
                        <GridActionsCellItem
                            icon={<EditIcon />}
                            label="Edit"
                            className="textPrimary"
                            onClick={handleEditClick(params.id)}
                            color="inherit"
                            placeholder={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}
                        />
                    </Tooltip>,
                    <Tooltip title="Delete" placement="right-start">
                        <GridActionsCellItem
                            icon={<DeleteIcon />}
                            label="Delete"
                            onClick={handleDeleteClick(params.id)}
                            color="inherit"
                            hidden={!showDelete}
                            placeholder={undefined}
                            onPointerEnterCapture={undefined}
                            onPointerLeaveCapture={undefined}
                        />
                    </Tooltip>,
                ];
            },
        },
    ];

    const getColumnVisibilityModel = (): { [key: string]: boolean } => {
        return columns.reduce(
            (columnVisibilityModel, column) => ({
                ...columnVisibilityModel,
                [column.field]: column.visible,
            }),
            {}
        );
    };

    return (
        <Box
            sx={{
                height: "75vh",
                width: "100%",
                "& .actions": {
                    color: "text.secondary",
                },
                "& .textPrimary": {
                    color: "text.primary",
                },
            }}
        >
            {rows && (
                <DataGrid
                    experimentalFeatures={{ newEditingApi: true }}
                    editMode="row"
                    rowModesModel={rowMode}
                    onRowModesModelChange={handleRowModeChange}
                    onRowEditStart={handleRowEditStart}
                    onRowEditStop={handleRowEditStop}
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={handleRowUpdateError}
                    components={{ Toolbar: AdminToolbar }}
                    componentsProps={{
                        toolbar: {
                            rows,
                            setRows,
                            setRowMode,
                            columnFields,
                            customToolbarComponents,
                            allowAddRecord,
                        },
                    }}
                    rows={rows}
                    columns={columns}
                    columnVisibilityModel={getColumnVisibilityModel()}
                    loading={isLoading}
                />
            )}
        </Box>
    );
};

export const AdminToolbar = ({ rows, setRows, setRowMode, columnFields, customToolbarComponents, allowAddRecord }) => {
    const date = new Date();
    const fileDate = `${date.getMonth()}-${date.getDate()}-${date.getFullYear()}`;

    const handleClick = () => {
        const id = rows.reduce((acc, field) => (field.id > acc ? field.id : acc), 0) + 1;

        const newRow = {
            id,
            ...columnFields.reduce((acc, field) => {
                if (field.editable) {
                    return { ...acc, [field.field]: getInitialValue(field) };
                }
                return acc;
            }, {}),
            isNew: true,
        };
        setRows((oldRows: any) => [...oldRows, newRow]);

        setRowMode((oldModel: any) => ({
            ...oldModel,
            [id]: { mode: GridRowModes.Edit },
        }));
    };

    return (
        <GridToolbarContainer
            sx={(theme) => ({
                backgroundColor: theme.palette.primary.main,
                paddingTop: "24px",
                paddingBottom: "24px",
                borderTopLeftRadius: "6px",
                borderTopRightRadius: "6px",
            })}
        >
            {allowAddRecord && (
                <EntityTableToolbarButton size="small" startIcon={<AddIcon />} onClick={handleClick}>
                    Add record
                </EntityTableToolbarButton>
            )}
            <GridToolbarColumnsButton
                sx={(theme) => ({ color: theme.palette.primary.contrastText })}
                placeholder={undefined}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
            />
            <GridToolbarExport
                csvOptions={{ fileName: `sieves-${fileDate}` }}
                sx={(theme) => ({ color: theme.palette.primary.contrastText })}
            />
            {[...customToolbarComponents]}
        </GridToolbarContainer>
    );
};

const getInitialValue = (field) => {
    switch (field?.type) {
        case "singleSelect":
            return field.valueOptions?.[0]?.value;
        case "boolean":
            return false;
        default:
            return "";
    }
};
