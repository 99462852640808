import { CircularProgress, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useAddOrUpdateSieve, useGetSieveSet } from "../../queries/QualityControlQueries";
import { EntityTableContainer } from "../../utilities/EntityTableContainer";
import { EntityTable } from "../../utilities/EntityTable";
import { SieveSet } from "../../types/QualityControlModels";
import { useMemo } from "react";

export const ManageSieveSetSieves = () => {
    const { sieveSetId } = useParams();
    const { data: sieveSet, isLoading } = useGetSieveSet({ sieveSetId });

    const columnFields = [
        { field: "id", headerName: "ID", type: "number", editable: false, flex: 1, align: "left", headerAlign: "left" },
        {
            field: "sieveSetId",
            headerName: "Sieve Set ID",
            editable: false,
            flex: 2,
            //visible: false,
            type: "number",
            align: "left",
            headerAlign: "left",
        },
        {
            field: "size",
            headerName: "Size",
            editable: true,
            flex: 1.5,
            visible: true,
            type: "string",
            align: "left",
            headerAlign: "left",
        },
        {
            field: "serial",
            headerName: "Serial",
            editable: true,
            flex: 2,
            visible: true,
            type: "string",
            align: "left",
            headerAlign: "left",
        },

        {
            field: "initialWeight",
            headerName: "Initial Weight",
            flex: 2,
            editable: true,
            visible: true,
            type: "number",
            align: "left",
            headerAlign: "left",
        },
        {
            field: "currentWeight",
            headerName: "Current Weight",
            editable: false,
            flex: 2,
            visible: true,
            type: "number",
            align: "left",
            headerAlign: "left",
        },

        { field: "totalUses", headerName: "Total Uses", editable: true, flex: 1.75, visible: true },
        {
            field: "isPan",
            headerName: "Pan",
            editable: true,
            flex: 1.5,
            visible: true,
            type: "boolean",
        },
        {
            field: "isActive",
            headerName: "Active",
            editable: true,
            flex: 1.5,
            visible: true,
            type: "boolean",
        },
        {
            field: "createdTimestamp",
            headerName: "Created",
            editable: false,
            flex: 3,
            visible: false,
            align: "left",
        },
        {
            field: "displayOrder",
            headerName: "Order",
            editable: true,
            flex: 1,
            visible: true,
            align: "left",
            headerAlign: "left",
            type: "number",
        },
    ];

    const sortedSieves = useMemo(() => {
        if (sieveSet?.sieves) {
            return [...sieveSet?.sieves]?.sort((a, b) => a?.displayOrder - b?.displayOrder);
        }
        return [];
    }, [sieveSet?.sieves]);

    return (
        <>
            {!!sieveSet?.sieves && sieveSetId && (
                <EntityTableContainer
                    title={<SievesCardTitle sieveSet={sieveSet} />}
                    table={
                        <EntityTable
                            entity={sortedSieves ?? []}
                            isLoading={isLoading}
                            useAddOrUpdateEntity={useAddOrUpdateSieve}
                            columnFields={columnFields}
                        />
                    }
                />
            )}
        </>
    );
};

const SievesCardTitle = ({ sieveSet }: { sieveSet: SieveSet }) => {
    return <>{sieveSet.name ? `${sieveSet.name} Sieves` : <CircularProgress size={24} />}</>;
};
