import { Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { routes } from "../../Routes";
import useBreadcrumbs from "use-react-router-breadcrumbs";

export const BreadcrumbsDisplay = () => {
    const breadcrumbs = useBreadcrumbs(routes);
    // if (breadcrumbs.length <= 1) {
    //     return null;
    // }
    return (
        <Breadcrumbs separator={<ChevronRightIcon fontSize="small" />}>
            {breadcrumbs.map(({ match, breadcrumb }, index) => {
                const isLast = index === breadcrumbs.length - 1;

                return isLast ? (
                    <Typography
                        key={match.pathname}
                        variant="button"
                        fontSize="small"
                        color={(theme) => theme.palette.primary.light}
                    >
                        {breadcrumb}
                    </Typography>
                ) : (
                    <Link key={match.pathname} to={match.pathname} style={{ textDecoration: "none" }}>
                        <Typography variant="button" fontSize="small" color={(theme) => theme.palette.primary.light}>
                            {breadcrumb}
                        </Typography>
                    </Link>
                );
            })}
        </Breadcrumbs>
    );
};
