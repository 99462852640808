import {
    Card,
    CardContent,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    styled,
} from "@mui/material";
import { useCallback, useState } from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { EntitySelect } from "../general/EntitySelect";
import { useGetSites } from "../../queries/AdminQueries";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { PaperItem } from "../../utilities/PaperItem";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { SelectOption } from "@mui/base/useOption";
import { useGetSampleOverviewByPlantId } from "../../queries/QualityControlQueries";
import { LocationOverview } from "../../types/QualityControlModels";
import { GrayTableCell } from "./subComponents/styled";

export const QualityControlOverview = () => {
    const { data: sites, isLoading: isLoadingSites } = useGetSites();
    const [isLoading, setIsLoading] = useState(false);
    const [selectedSite, setSelectedSite] = useState(null);
    const [selectedPlant, setSelectedPlant] = useState(null);
    const [startDate, setStartDate] = useState(dayjs(Date.now()).subtract(2, "week"));
    const [endDate, setEndDate] = useState(dayjs(Date.now()));

    const { data: locationOverview, refetch } = useGetSampleOverviewByPlantId(
        selectedPlant,
        startDate.toJSON(),
        endDate.toJSON()
    );

    const handleSiteChange = useCallback((option: SelectOption<string>) => {
        setSelectedSite(option.value);
    }, []);

    const handlePlantChange = (option: SelectOption<string>) => {
        setSelectedPlant(option.value);
    };

    const handleStartDateChange = (value) => {
        setStartDate(dayjs(value));
    };

    const handleEndDateChange = (value) => {
        setEndDate(dayjs(value));
    };

    const handleGenerateOverview = useCallback(() => {
        setIsLoading(true);
        refetch().then(() => setIsLoading(false));
    }, [refetch]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <PaperItem>
                    <Grid item xs={12} marginBottom={2}>
                        <Typography variant="h4">Overview</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <EntitySelect
                                    label="Site"
                                    entities={sites}
                                    isLoadingEntities={isLoadingSites}
                                    selectedValues={selectedSite}
                                    handleChange={handleSiteChange}
                                    disabled={isLoadingSites}
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <EntitySelect
                                    label="Plant"
                                    entities={sites?.find((site) => site.id === selectedSite)?.plants}
                                    isLoadingEntities={isLoadingSites}
                                    selectedValues={selectedPlant}
                                    handleChange={handlePlantChange}
                                    disabled={isLoadingSites}
                                    size="small"
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        label="Start Date"
                                        onChange={handleStartDateChange}
                                        value={startDate}
                                        disabled={false}
                                        slotProps={{
                                            textField: { fullWidth: true, size: "small" },
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={3}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateTimePicker
                                        label="End Date"
                                        onChange={handleEndDateChange}
                                        value={endDate}
                                        disabled={false}
                                        slotProps={{
                                            textField: { fullWidth: true, size: "small" },
                                        }}
                                    />
                                </LocalizationProvider>
                            </Grid>
                            <Grid item xs={2}>
                                <LoadingButton
                                    variant="contained"
                                    onClick={handleGenerateOverview}
                                    loading={isLoading}
                                    disabled={!selectedSite || !startDate || !endDate}
                                    fullWidth
                                >
                                    Generate Overview
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </PaperItem>
            </Grid>
            {locationOverview?.map((overview, index) => (
                <Grid item key={index}>
                    {overview.sieveStatistics?.length ? <LocationOverviewCard key={index} overview={overview} /> : null}
                </Grid>
            ))}
        </Grid>
    );
};

const LocationOverviewCard = ({ overview }: { overview: LocationOverview }) => {
    const SieveStatisticsRow = ({ sieveStatistics }) => (
        <TableRow>
            <TableCell>{sieveStatistics.sieveSize}</TableCell>
            <TableCell>{sieveStatistics.latestPercentFinal}</TableCell>
            <GrayTableCell>{sieveStatistics.average}</GrayTableCell>
            <GrayTableCell>{sieveStatistics.standardDeviation}</GrayTableCell>
            <GrayTableCell>{sieveStatistics.max}</GrayTableCell>
            <GrayTableCell>{sieveStatistics.min}</GrayTableCell>
        </TableRow>
    );
    const RangeStatisticsRow = ({ rangeStatistics }) => (
        <TableRow>
            <TableCell>{rangeStatistics.rangeName}</TableCell>
            <TableCell>{rangeStatistics.latestValue}</TableCell>
            <GrayTableCell>{rangeStatistics.average}</GrayTableCell>
            <GrayTableCell>{rangeStatistics.standardDeviation}</GrayTableCell>
            <GrayTableCell>{rangeStatistics.max}</GrayTableCell>
            <GrayTableCell>{rangeStatistics.min}</GrayTableCell>
        </TableRow>
    );
    const PlantSettingStatisticsRow = ({ plantSettingStatistics }) => (
        <TableRow>
            <TableCell>{plantSettingStatistics.deviceName}</TableCell>
            <TableCell>{plantSettingStatistics.latestValue}</TableCell>
            <GrayTableCell>{plantSettingStatistics.average}</GrayTableCell>
            <GrayTableCell>{plantSettingStatistics.standardDeviation}</GrayTableCell>
            <GrayTableCell>{plantSettingStatistics.max}</GrayTableCell>
            <GrayTableCell>{plantSettingStatistics.min}</GrayTableCell>
        </TableRow>
    );
    return (
        <Card>
            <CardContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell
                                align="center"
                                colSpan={6}
                                sx={(theme) => ({
                                    backgroundColor: theme.palette.primary.dark,
                                    color: theme.palette.primary.contrastText,
                                })}
                            >
                                {overview.latestSample.location.name}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="center" colSpan={2}>
                                Latest Sample ID: {overview.latestSample.id}
                            </TableCell>
                            <GrayTableCell align="center" colSpan={4}>
                                Date Range Statistics
                            </GrayTableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Sieve</TableCell>
                            <TableCell>Value</TableCell>
                            <GrayTableCell>Avg</GrayTableCell>
                            <GrayTableCell>Std</GrayTableCell>
                            <GrayTableCell>Max</GrayTableCell>
                            <GrayTableCell>Min</GrayTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {overview?.sieveStatistics ? (
                            overview.sieveStatistics.map((result, index) => (
                                <SieveStatisticsRow key={index} sieveStatistics={result} />
                            ))
                        ) : (
                            <></>
                        )}
                        <TableRow>
                            <TableCell
                                colSpan={6}
                                align="center"
                                sx={(theme) => ({
                                    color: theme.palette.common.white,
                                    backgroundColor: theme.palette.grey[800],
                                })}
                            >
                                Calculations
                            </TableCell>
                        </TableRow>
                        {overview?.rangeStatistics.map((rangeStatistics, index) => (
                            <RangeStatisticsRow key={index} rangeStatistics={rangeStatistics} />
                        ))}
                        <TableRow>
                            <TableCell
                                colSpan={6}
                                align="center"
                                sx={(theme) => ({
                                    color: theme.palette.common.white,
                                    backgroundColor: theme.palette.grey[800],
                                })}
                            >
                                Plant Settings
                            </TableCell>
                        </TableRow>
                        {overview?.plantSettingStatistics.map((plantSettingStatistics, index) => (
                            <PlantSettingStatisticsRow key={index} plantSettingStatistics={plantSettingStatistics} />
                        ))}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
};

const MissingSieveStatisticsCard = ({ overview }: { overview: LocationOverview }) => {
    return (
        <Card>
            <CardContent>
                <Typography>Not enough analysis data to display overview for location ID: {overview?.locationId}</Typography>
            </CardContent>
        </Card>
    );
};
