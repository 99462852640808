import { ThemeOptions, darken, lighten } from "@mui/material/styles";
declare module "@mui/material/styles" {
    interface Palette {
        appbar: Palette["primary"];
    }

    interface PaletteOptions {
        appbar: PaletteOptions["primary"];
    }

    interface PaletteColor {
        darker?: string;
        lighter?: string;
    }

    interface SimplePaletteColorOptions {
        darker?: string;
        lighter?: string;
    }
}

export const themeOptions: ThemeOptions = {
    shape: { borderRadius: 3 },
    palette: {
        mode: "light",
        primary: {
            main: "#00488a",
            light: lighten("#00488a", 0.4),
            lighter: lighten("#00488a", 0.6),
        },
        secondary: {
            main: "rgb(186,157,97)",
            dark: darken("rgb(186,157,97)", 0.2),
            light: lighten("rgb(186,157,97)", 0.4),
            contrastText: "#fff",
        },
        success: {
            main: "rgb(135, 197, 102)",
            contrastText: "#fff",
        },
        warning: {
            main: "rgb(255, 97, 29)",
        },
        text: {
            primary: "rgba(83,83,83, 1)",
            secondary: "rgba(83,83,83, 0.54)",
            disabled: "rgba(83,83,83, 0.38)",
        },
        background: {
            default: "#DADADA",
        },
        appbar: { main: lighten("#333333", 0.05), contrastText: "#fff" },
    },
    typography: {
        fontFamily: [].join(","),
    },
};
