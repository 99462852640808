import { useAddOrUpdatePlantMutation, useGetPlants, useGetSites } from "../../queries/AdminQueries";
import { Typography } from "@mui/material";
import { EntityTableContainer } from "../../utilities/EntityTableContainer";
import { EntityTable } from "../../utilities/EntityTable";
import { useMemo } from "react";
import { Site } from "../../types/QualityControlModels";

export const ManagePlants = () => {
    const { data: sites } = useGetSites();
    const { data: plants, isLoading: isGetPlantsLoading } = useGetPlants();

    const columnFields = useMemo(
        () => [
            { field: "id", headerName: "ID", flex: 2 },
            {
                field: "siteId",
                headerName: "Site",
                flex: 2,
                editable: true,
                type: "singleSelect",
                valueOptions: sites?.map((site: Site) => {
                    return { label: site.name, value: site.id };
                }),
                renderCell: ({ row }) => {
                    return sites?.find((site) => site.id === row.siteId)?.name;
                },
            },
            { field: "name", headerName: "Name", flex: 2, editable: true },
            { field: "description", headerName: "Description", flex: 2, editable: true },
            {
                field: "isActive",
                headerName: "Visible",
                flex: 2,
                type: "boolean",
                editable: true,
            },
        ],
        [sites]
    );

    return (
        <>
            {plants && (
                <EntityTableContainer
                    title="Plants"
                    table={
                        <EntityTable
                            entity={plants}
                            columnFields={columnFields}
                            useAddOrUpdateEntity={useAddOrUpdatePlantMutation}
                            isLoading={isGetPlantsLoading}
                        />
                    }
                />
            )}
        </>
    );
};
