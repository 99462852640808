import { useGetPlants, useGetSites } from "../../queries/AdminQueries";
import { useCallback, useMemo } from "react";
import { useAddSampleLocationMutation, useGetSampleLocations } from "../../queries/QualityControlQueries";
import { EntityTableContainer } from "../../utilities/EntityTableContainer";
import { EntityTable } from "../../utilities/EntityTable";
import { Plant, Site } from "../../types/QualityControlModels";
import { GridValueSetterParams } from "@mui/x-data-grid";

export const ManageLocations = () => {
    const { data: sites } = useGetSites();
    const { data: plants } = useGetPlants();
    const { data: locations, isLoading: isGetLocationsLoading, isError } = useGetSampleLocations();

    const siteOptions = useMemo(
        () => sites?.map?.((site: Site) => ({ value: site.id, label: site.name })) ?? [],
        [sites]
    );

    const getPlantOptions = useCallback(
        (siteId) => siteId ? plants?.filter((plant) => plant.siteId === siteId).map?.((plant: Plant) => {
                    return { value: plant.id, label: plant.name };
                }) : [],
        [plants]
    );

    const columnFields = useMemo(() => {
        return [
            { field: "id", headerName: "ID", flex: 2, editable: false },
            {
                field: "siteId",
                headerName: "Site",
                flex: 2,
                editable: true,
                required: true,
                type: "singleSelect",
                valueOptions: siteOptions,
                valueGetter: ({ row }) => {
                    return row.plant?.siteId;
                },
                valueSetter: ({ row, value }) => {
                    const siteId = value;
                    return { ...row, siteId };
                },
                renderCell: ({ row }) => {
                    return row.plant?.site?.name;
                },
            },
            {
                field: "plantId",
                headerName: "Plant",
                flex: 2,
                editable: true,
                required: true,
                type: "singleSelect",
                valueOptions: ({ row }) => {
                    return getPlantOptions(Number(row?.siteId));
                },
                renderCell: ({ row }) => {
                    return row.plant?.name;
                },
            },
            { field: "name", headerName: "Name", flex: 2, editable: true },
            { field: "description", headerName: "Description", flex: 2, editable: true },
            {
                field: "isMoistureRateRequired",
                headerName: "Moisture Rate Required",
                flex: 2,
                type: "boolean",
                editable: true,
            },
            {
                field: "isCoreSampleLocation",
                headerName: "Core Sample",
                flex: 2,
                type: "boolean",
                editable: true,
            },
            {
                field: "isActive",
                headerName: "Visible",
                flex: 2,
                type: "boolean",
                editable: true,
            },
        ];
    }, [getPlantOptions, siteOptions]);

    return (
        <>
            {locations && (
                <EntityTableContainer
                    title="Locations"
                    table={
                        <EntityTable
                            entity={locations}
                            columnFields={columnFields}
                            useAddOrUpdateEntity={useAddSampleLocationMutation}
                            isLoading={isGetLocationsLoading}
                        />
                    }
                />
            )}
        </>
    );
};
