import { useCallback, useEffect, useMemo, useState } from "react";
import { AsyncSelect } from "./AsyncSelect";
import { SelectOption } from "../../utilities/SelectUtilities";

type EntitySelectProps = {
    label: string;
    entities: any[];
    isLoadingEntities: boolean;
    selected?: any;
    selectedValues?: any;
    handleChange: (event) => void;
    filter?: (entity?: any) => any;
    isMulti?: boolean;
    disabled?: boolean;
    size?: string;
};

export const EntitySelect = ({
    label,
    entities,
    isLoadingEntities,
    selected = null,
    selectedValues = null,
    handleChange,
    filter = (entity?: any) => true,
    isMulti = false,
    disabled = false,
    size = "medium",
}: EntitySelectProps) => {
    //const { data: entities, isLoading } = getEntity();

    const options = useMemo(() => {
        return (
            entities
                ?.filter?.((entity: any) => filter(entity))
                ?.map?.((entity: any) => ({
                    label: entity.name,
                    value: entity.id,
                })) ?? []
        );
    }, [entities, filter]);

    const selectedOptions = useMemo(() => {
        if (!options?.length) {
            return isMulti ? [] : null;
        }

        if (!selectedValues && !!selected) {
            return [selected];
        }

        if (!isMulti) {
            const option = options?.find((option) => option.value === selectedValues);
            return option ? option : null;
        }

        return options.filter((option) => selectedValues?.includes(option.value));
    }, [isMulti, options, selected, selectedValues]);

    return (
        <AsyncSelect
            label={label}
            options={options}
            selectedOptions={selectedOptions}
            handleChange={handleChange}
            isLoading={isLoadingEntities}
            disabled={disabled}
            isMulti={isMulti}
            size={size}
        />
    );
};
