import { Grid, Card, CardContent, Stack, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useGetSites } from "../../queries/AdminQueries";
import { PaperItem } from "../../utilities/PaperItem";

export const Home = () => {
    const { data: sites, isLoading } = useGetSites();

    const navigate = useNavigate();
    return (
        <Grid container>
            <PaperItem>
                <Grid item xs={12} marginBottom={2}>
                    <Typography variant="h4">Home</Typography>
                </Grid>

                <Grid item xs={12}>
                    <Grid container>
                        <Grid container>
                            <Grid item xs={12}>
                                <Stack alignItems="flex-start">
                                    {sites
                                        ?.filter((site) => site.isActive)
                                        ?.map((site) => (
                                            <Button key={site.id} onClick={() => navigate(`./qc/${site.code}`)}>
                                                {site?.name?.toUpperCase()} Quality Control
                                            </Button>
                                        ))}

                                    <Button onClick={() => navigate("./admin")}>Admin</Button>
                                    <Button onClick={() => navigate("./profile")}>Profile</Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}></Grid>
            </PaperItem>
        </Grid>
    );
};

export default Home;
