import {
    Autocomplete,
    Button,
    Card,
    CardContent,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { formatTimestamp } from "../../../utilities/DateUtilities";
import { CrushAnalysis, Sample } from "../../../types/QualityControlModels";
import { useCallback, useEffect, useMemo, useState } from "react";
import { samplesToExcel } from "../../../utilities/ExportUtilities";
import Close from "@mui/icons-material/Close";
import { displayLocalTimestamp } from "../../../hooks/general/dayjs";

export const SampleAdmin = ({
    sample,
    unsavedChanges,
    showTitle = true,
    disabled,
}: {
    sample: Sample;
    unsavedChanges: boolean;
    showTitle: boolean;
    isReadOnly: boolean;
    disabled: boolean;
}) => {
    const [showGenerateCOAModal, setShowGenerateCOAModal] = useState(false);
    const [isGeneratingCOA, setIsGeneratingCOA] = useState(false);
    const handleShowGenerateCOAModal = () => setShowGenerateCOAModal(true);
    const handleGenerateCOA = () => {};

    const handleSampleExport = useCallback(() => samplesToExcel([sample]), [sample]);

    return sample ? (
        <>
            {!unsavedChanges && showGenerateCOAModal && (
                <GenerateCOAModal
                    show={showGenerateCOAModal}
                    sample={sample}
                    handleModalClose={() => setShowGenerateCOAModal(false)}
                    handleGenerateCOA={handleGenerateCOA}
                    isGeneratingCOA={isGeneratingCOA}
                    disabled={disabled}
                />
            )}
            <Card elevation={0}>
                <CardContent>
                    <Grid container spacing={2}>
                        {showTitle && (
                            <Grid item xs={12}>
                                <Typography variant="h6">Admin</Typography>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Grid
                                container
                                spacing={2}
                                sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.grey[300]}` })}
                                paddingBottom={2}
                            >
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">Actions</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="column" alignItems="flex-start" justifyContent="flex-start">
                                        <Button
                                            onClick={() => window.open(`../../sample-group/${sample.sampleGroupId}`)}
                                            disabled={disabled}
                                        >
                                            View Sample Group
                                        </Button>
                                        <Button onClick={handleSampleExport} disabled={disabled}>
                                            Export Sample
                                        </Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid
                                container
                                spacing={2}
                                sx={(theme) => ({ borderBottom: `1px solid ${theme.palette.grey[300]}` })}
                                paddingBottom={2}
                            >
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">Certificate of Analysis</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="column" alignItems="flex-start" justifyContent="flex-start">
                                        <Button onClick={handleShowGenerateCOAModal} disabled={disabled}>Generate COA</Button>
                                        <Button disabled={disabled}>View Generated COAs</Button>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="subtitle2">Metadata</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack
                                        direction="column"
                                        alignItems="flex-start"
                                        justifyContent="flex-start"
                                        spacing={2}
                                    >
                                        <Chip label={`Created On: ${displayLocalTimestamp(sample.createdTimestampUtc).format("MM/DD/YYYY hh:mm:ss A")}`} />
                                        <Chip label={`Created By: ${sample.createdBy}`} />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    ) : (
        <SampleAdminSkeleton />
    );
};

export const SampleAdminSkeleton = () => {
    return (
        <Stack direction="column">
            <Skeleton />
        </Stack>
    );
};

const GenerateCOAModal = ({
    show,
    sample,
    handleModalClose,
    handleGenerateCOA,
    isGeneratingCOA,
    disabled,
}: {
    show: boolean;
    sample: Sample;
    handleModalClose: () => void;
    handleGenerateCOA: () => void;
    isGeneratingCOA: boolean;
    disabled: boolean;
}) => {
    const isCrushAnalysisComplete = useMemo(() => {
        //there needs to be at least one crush test average
        return sample?.crushAnalyses.some((crushAnalysis) => !!crushAnalysis.average);
    }, [sample?.crushAnalyses]);

    const sampleTypeOptions = [
        { label: "-40/+70", value: "fortySeventy" },
        { label: "-50/+140", value: "fiftyOneForty" },
        { label: "Other", value: "other" },
    ];

    const coaPropertyOptions = [
        { label: "Product Name", value: "productName", disabled: false },
        { label: "Roundness - Sphericity", value: "roundnessSphericity", disabled: false },
        { label: "Density", value: "density", disabled: false },
        { label: "Acid Solubility", value: "acidSolubility", disabled: false },
        { label: "Silt Percent", value: "siltPercent", disabled: false },
        { label: "Moisture Rate", value: "moistureRate", disabled: false },
        { label: "Turbidity", value: "turbidity", disabled: false },
        {
            label: isCrushAnalysisComplete ? "Crush Resistance" : "Crush Resistance (No crush tests found)",
            value: "crushResistance",
            disabled: !isCrushAnalysisComplete,
        },
    ];

    const [productName, setProductName] = useState(null);
    const [bulkGrams, setBulkGrams] = useState(null);
    const [bulkPounds, setBulkPounds] = useState(null);
    const [acidSolubility, setAcidSolubility] = useState(null);
    const [selectedSampleType, setSelectedSampleType] = useState(null);
    const [selectedCOAPropertyOptions, setSelectedCOAProperties] = useState([]);

    const handleSampleTypeChange = (event) => {
        setSelectedSampleType(event.target.value);
    };
    const handleCOAPropertiesChange = (options) => {
        setSelectedCOAProperties(options);
    };

    const handleCancel = () => {
        setSelectedSampleType(null);
        setSelectedCOAProperties([]);
        handleModalClose();
    };

    const validationMessage = "A valid value is required when property is included in COA";
    return (
        <Dialog open={show} onClose={handleModalClose} fullScreen fullWidth maxWidth="lg">
            <DialogTitle>
                Configure COA
                <IconButton
                    aria-label="close-modal"
                    onClick={handleModalClose}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <Close />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2} marginTop={0.1}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="sample-type-select-label">Sample Type</InputLabel>
                            <Select
                                labelId="sample-type-select-label"
                                label="Sample Type"
                                onChange={handleSampleTypeChange}
                                value={selectedSampleType}
                            >
                                {sampleTypeOptions.map((option) => (
                                    <MenuItem value={option.value}>{option.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            multiple={true}
                            options={coaPropertyOptions}
                            getOptionLabel={(option) => option.label}
                            getOptionDisabled={(option) => option.disabled}
                            onChange={(event, newValue) => {
                                handleCOAPropertiesChange(newValue);
                            }}
                            isOptionEqualToValue={(option, value) => {
                                return option?.value === value?.value;
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Select Properties to Include"
                                    fullWidth
                                />
                            )}
                            disabled={disabled || isGeneratingCOA}
                            fullWidth
                        />
                    </Grid>
                    {/* Show inputs in order they are selected */}
                    {selectedCOAPropertyOptions.map((propertyOption) => {
                        return (
                            <>
                                {propertyOption.value === "productName" && (
                                    <>
                                        <Grid item xs={12}>
                                            {/* <Typography>{propertyOption.label}</Typography> */}
                                            <TextField
                                                label="Product Name"
                                                onChange={(e) => setProductName(e.target.value)}
                                                error={!productName}
                                                helperText={!productName && validationMessage}
                                                required
                                                fullWidth
                                            />
                                        </Grid>
                                    </>
                                )}
                                {propertyOption.value === "roundnessSphericity" && (
                                    <>
                                        {/* <Grid item xs={12}>
											<Typography>{propertyOption.label}</Typography>
										</Grid> */}
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Roundness"
                                                value={sample.roundness}
                                                disabled
                                                required
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Sphericity"
                                                value={sample.sphericity}
                                                disabled
                                                required
                                                fullWidth
                                            />
                                        </Grid>
                                    </>
                                )}
                                {propertyOption.value === "density" && (
                                    <>
                                        {/* <Grid item xs={12}>
										<Typography>{propertyOption.label}</Typography>
									</Grid> */}
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Bulk"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            g/cm<sup>3</sup>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={(e) => setBulkGrams(e.target.value)}
                                                error={!bulkGrams}
                                                helperText={!bulkGrams && validationMessage}
                                                required
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                label="Bulk"
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            lb/ft<sup>3</sup>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                onChange={(e) => setBulkPounds(e.target.value)}
                                                error={!bulkPounds}
                                                helperText={!bulkPounds && validationMessage}
                                                required
                                                fullWidth
                                            />
                                        </Grid>
                                    </>
                                )}
                                {propertyOption.value === "acidSolubility" && (
                                    <>
                                        <Grid item xs={12}>
                                            {/* <Typography>{propertyOption.label}</Typography> */}
                                            <TextField
                                                label={propertyOption.label}
                                                onChange={(e) => setAcidSolubility(e.target.value)}
                                                error={!acidSolubility}
                                                helperText={!acidSolubility && validationMessage}
                                                required
                                                fullWidth
                                            />
                                        </Grid>
                                    </>
                                )}
                                {propertyOption.value === "siltPercent" && (
                                    <>
                                        <Grid item xs={12}>
                                            {/* <Typography>{propertyOption.label}</Typography> */}
                                            <TextField
                                                label={propertyOption.label}
                                                value={sample.siltPercent}
                                                fullWidth
                                                disabled
                                            />
                                        </Grid>
                                    </>
                                )}
                                {propertyOption.value === "moistureRate" && (
                                    <>
                                        <Grid item xs={12}>
                                            {/* <Typography>{propertyOption.label}</Typography> */}
                                            <TextField
                                                label={propertyOption.label}
                                                value={sample.siltPercent}
                                                disabled
                                                fullWidth
                                            />
                                        </Grid>
                                    </>
                                )}
                                {propertyOption.value === "turbidity" && (
                                    <>
                                        <Grid item xs={12}>
                                            {/* <Typography>{propertyOption.label}</Typography> */}
                                            <TextField
                                                label={propertyOption.label}
                                                value={sample.turbidity}
                                                fullWidth
                                                disabled
                                            />
                                        </Grid>
                                    </>
                                )}
                                {propertyOption.value === "crushResistance" && (
                                    <>
                                        <Grid item xs={12}>
                                            {/* <Typography>{propertyOption.label}</Typography> */}
                                            <CrushResistanceTable crushAnalyses={sample?.crushAnalyses} />
                                        </Grid>
                                    </>
                                )}
                            </>
                        );
                    })}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleGenerateCOA} variant="contained">
                    Generate COA
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const CrushResistanceTable = ({ crushAnalyses }: { crushAnalyses: CrushAnalysis[] }) => {
    const [maxAvgCrushTestPass, setMaxAvgCrushTestPass] = useState(null);
    const [minAvgCrushTestFail, setMinAvgCrushTestFail] = useState(null);
    useEffect(() => {
        if (crushAnalyses.length > 0) {
            let maxAvgCrushTestPass = { name: null, average: -Infinity };
            let minAvgCrushTestFail = { name: null, average: Infinity };
            crushAnalyses.forEach((crushTest) => {
                if (crushTest?.average) {
                    if (crushTest?.isPass && crushTest?.average > maxAvgCrushTestPass?.average) {
                        maxAvgCrushTestPass = {
                            name: crushTest.name,
                            average: crushTest.average,
                        };
                    }
                    if (!crushTest?.isPass && crushTest?.average < minAvgCrushTestFail?.average) {
                        minAvgCrushTestFail = {
                            name: crushTest.name,
                            average: crushTest.average,
                        };
                    }
                }
            });

            setMaxAvgCrushTestPass(maxAvgCrushTestPass);
            setMinAvgCrushTestFail(minAvgCrushTestFail);
        }
    }, [crushAnalyses]);

    return crushAnalyses?.length > 0 ? (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Stress (PSI)</TableCell>
                    <TableCell>% Fines</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {maxAvgCrushTestPass?.average && (
                    <TableRow>
                        <TableCell>{maxAvgCrushTestPass?.name}</TableCell>
                        <TableCell>{maxAvgCrushTestPass?.average}%</TableCell>
                    </TableRow>
                )}
                {minAvgCrushTestFail?.average && (
                    <TableRow>
                        <TableCell>{minAvgCrushTestFail?.name}</TableCell>
                        <TableCell>{minAvgCrushTestFail?.average}%</TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    ) : (
        <>No crush values saved.</>
    );
};
