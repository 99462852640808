import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import Card from "@mui/material/Card";
import FormControl from "@mui/material/FormControl";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AsyncButton } from "../general/AsyncButton";
import { UserSelect } from "../general/UserSelect";
import { SampleStatus, UserGroup, UserRole } from "../../types/QualityControlEnums";
import { SelectOption } from "../../utilities/SelectUtilities";
import { Button, CardContent, CardHeader, Grid, TextField, Typography } from "@mui/material";
import { useAddSampleGroup, useGetCompositeTypes, useGetTestTypes } from "../../queries/QualityControlQueries";
import { useNavigate, useParams } from "react-router-dom";
import { EntitySelect } from "../general/EntitySelect";
import { useGetSites, useGetUsers, useGetUsersInGroup } from "../../queries/AdminQueries";
import { RepeatabilityCounter } from "./subComponents/RepeatabilityCounter";
import { SampleGroup } from "../../types/QualityControlModels";
import { DateTimeField } from "@mui/x-date-pickers";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useAcquireAccessToken } from "../../hooks/general/useAcquireAccessToken";
import { useUserInfo } from "../../hooks/general/useUserInfo";
import { useCurrentSite } from "../../hooks/general/useCurrentSite";

export const QualityControlAddSampleGroup = () => {
    return (
        <>
            <Card
                sx={(theme) => ({
                    backgroundColor: theme.palette.grey[200],
                })}
            >
                <CardHeader
                    title={
                        <Grid container>
                            <Grid item xs={2}>
                                <Typography variant="h6">Add Sample Group</Typography>
                            </Grid>
                            <Grid item xs={6}></Grid>
                            <Grid item xs={4} justifyContent="flex-end" alignItems="center" display="flex"></Grid>
                        </Grid>
                    }
                />
                <CardContent>
                    <Card elevation={0}>
                        <CardContent>
                            <AddSampleForm />
                        </CardContent>
                    </Card>
                </CardContent>
            </Card>
        </>
    );
};

export const AddSampleForm = ({ onSuccess = null }: { onSuccess?: () => void }) => {
    const { site: currentSite } = useCurrentSite();
    const navigate = useNavigate();
    const { userInfo, isLoading: isLoadingUserInfo } = useUserInfo();
    const [sampleGroupForm, setSampleGroupForm] = useState({
        samples: [],
        locations: [],
        numberOfSamplesInGroup: 1,
        siteId: null,
        plantId: null,
        operator: null,
        operatorName: null,
        sampler: null,
        samplerName: null,
        labTech: null,
        labTechName: null,
        compositeTypeId: null,
        testTypeId: null,
        createdBy: null,
        createdByName: null,
        sampledTimestamp: dayjs(Date.now()),
        sampledTimestampUtc: new Date().toISOString(),
    });
    const [isFormValid, setIsFormValid] = useState(true);
    const { data: sites, isLoading: isLoadingSites } = useGetSites();
    const { data: testTypes, isLoading: isLoadingTestTypes } = useGetTestTypes();
    const { data: compositeTypes, isLoading: isLoadingCompositeTypes } = useGetCompositeTypes();
    const { mutate: addSampleGroup, isLoading: isLoadingAddSampleGroup } = useAddSampleGroup();

    const selectedSite = useMemo(
        () => sites?.find((site) => site.id === sampleGroupForm.siteId),
        [sampleGroupForm.siteId, sites]
    );
    const selectedPlant = useMemo(
        () => selectedSite?.plants?.find((plant) => plant.id === sampleGroupForm.plantId),
        [sampleGroupForm?.plantId, selectedSite?.plants]
    );
    const handleSiteChange = (option: SelectOption) => {
        setSampleGroupForm((prevState) => ({ ...prevState, siteId: Number(option.value) }));
    };

    const handlePlantChange = (option: SelectOption) => {
        setSampleGroupForm((prevState) => ({
            ...prevState,
            plantId: Number(option.value),
            locations: [],
        }));
    };

    const handleLocationsChange = (options: SelectOption[]) => {
        setSampleGroupForm((prevState) => ({
            ...prevState,
            locations: options.map((option) => Number(option.value)),
        }));
    };

    const handleTestTypeChange = (option: SelectOption) => {
        setSampleGroupForm((prevState) => ({
            ...prevState,
            testTypeId: Number(option.value),
        }));
    };

    const handleCompositeTypeChange = (option: SelectOption) => {
        setSampleGroupForm((prevState) => ({
            ...prevState,
            compositeTypeId: Number(option.value),
        }));
    };

    const handleAddSampleGroup = () => {
        const sampleGroup: SampleGroup = {
            plantId: sampleGroupForm.plantId,
            samples: sampleGroupForm.samples,
            createdBy: sampleGroupForm.createdBy,
            createdByName: sampleGroupForm.createdByName,
            sampledTimestamp: sampleGroupForm.sampledTimestamp.toDate(),
            sampledTimestampUtc: sampleGroupForm.sampledTimestamp.utc().toString(),
        };

        addSampleGroup(
            { sampleGroup },
            {
                onSuccess: (response) => {
                    if (onSuccess) {
                        onSuccess();
                    }
                    toast.success(
                        <>
                            <Typography>Sample created successfully</Typography>
                            <Button
                                LinkComponent={Link}
                                onClick={() =>
                                    navigate(`/qc/${currentSite.code}/samples/${response?.samples?.[0]?.id}/edit`)
                                }
                            >
                                Go to Sample
                            </Button>
                        </>
                    );
                },
            }
        );
    };

    const handleOperatorChange = (selectedOption: SelectOption) => {
        setSampleGroupForm((prevState) => ({
            ...prevState,
            operator: selectedOption.value,
            operatorName: selectedOption.label,
        }));
    };

    const handleSamplerChange = (selectedOption: SelectOption) => {
        setSampleGroupForm((prevState) => ({
            ...prevState,
            sampler: selectedOption.value,
            samplerName: selectedOption.label,
        }));
    };

    const handleLabTechChange = (selectedOption: SelectOption) => {
        setSampleGroupForm((prevState) => ({
            ...prevState,
            labTech: selectedOption.value,
            labTechName: selectedOption.label,
        }));
    };

    const handleDateTimeChange = (event) => {
        setSampleGroupForm((prevState) => ({
            ...prevState,
            sampledTimestamp: dayjs(event.target?.value),
        }));
    };

    const handleNumberOfSamplesInGroupChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSampleGroupForm((prevState) => ({
            ...prevState,
            numberOfSamplesInGroup: Number(event.target?.value),
        }));
    };

    useEffect(() => {
        if (currentSite && !sampleGroupForm.siteId) {
            setSampleGroupForm((prev) => ({ ...prev, siteId: currentSite.id }));
        }
    }, [currentSite, sampleGroupForm]);

    useEffect(() => {
        if (isFormValid) {
            sampleGroupForm.locations.forEach((locationId) => {
                for (let i = 0; i < sampleGroupForm.numberOfSamplesInGroup; i++) {
                    setSampleGroupForm((prevState) => ({
                        ...prevState,
                        createdBy: userInfo?.displayName ?? "",
                        samples: [
                            ...prevState.samples,
                            {
                                plantId: prevState.plantId,
                                locationId: Number(locationId),
                                testTypeId: Number(prevState.testTypeId),
                                compositeTypeId: prevState.compositeTypeId,
                                operator: prevState.operator,
                                operatorName: prevState.operatorName,
                                sampler: prevState.sampler,
                                samplerName: prevState.samplerName,
                                labTech: prevState.labTech,
                                labTechName: prevState.labTechName,
                                status: SampleStatus.New,
                                createdBy: userInfo?.id,
                                createdByName: userInfo?.displayName ?? userInfo?.name,
                                sampledTimestamp: sampleGroupForm.sampledTimestamp.format(),
                                sampledTimestampUtc: sampleGroupForm.sampledTimestampUtc,
                            },
                        ],
                    }));
                }
            });
        }
    }, [isFormValid, sampleGroupForm.sampledTimestamp, sampleGroupForm.locations, sampleGroupForm.numberOfSamplesInGroup, userInfo, sampleGroupForm.sampledTimestampUtc]);

    useEffect(() => {
        setIsFormValid(
            !!sampleGroupForm.siteId &&
                !!sampleGroupForm.plantId &&
                !!sampleGroupForm.locations?.length &&
                !!sampleGroupForm.operator &&
                !!sampleGroupForm.sampler &&
                !!sampleGroupForm.labTech &&
                !!sampleGroupForm.sampledTimestamp &&
                !!sampleGroupForm.numberOfSamplesInGroup
        );
    }, [
        sampleGroupForm.sampledTimestamp,
        sampleGroupForm.labTech,
        sampleGroupForm.locations?.length,
        sampleGroupForm.numberOfSamplesInGroup,
        sampleGroupForm.operator,
        sampleGroupForm.plantId,
        sampleGroupForm.sampler,
        sampleGroupForm.siteId,
    ]);
    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <RepeatabilityCounter />
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <EntitySelect
                        label="Site"
                        entities={sites?.filter((s) => s.isActive)}
                        isLoadingEntities={isLoadingSites}
                        selectedValues={currentSite ? currentSite.id : sampleGroupForm.siteId}
                        handleChange={handleSiteChange}
                        disabled={isLoadingAddSampleGroup || !!currentSite?.id}
                        size="small"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <EntitySelect
                        label="Plant"
                        entities={selectedSite?.plants?.filter((p) => p.isActive)}
                        isLoadingEntities={isLoadingSites}
                        selectedValues={sampleGroupForm.plantId}
                        handleChange={handlePlantChange}
                        disabled={isLoadingAddSampleGroup}
                        size="small"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <EntitySelect
                        label="Locations"
                        entities={selectedPlant?.locations?.filter((l) => l.isActive)}
                        isLoadingEntities={isLoadingSites}
                        selectedValues={sampleGroupForm.locations}
                        handleChange={handleLocationsChange}
                        isMulti={true}
                        disabled={isLoadingAddSampleGroup}
                        size="small"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <DateTimeField
                        label="Sampled Date/Time"
                        onChange={handleDateTimeChange}
                        value={sampleGroupForm.sampledTimestamp}
                        disabled={isLoadingAddSampleGroup}
                        size="small"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <TextField
                        label="Number of samples in group"
                        variant="outlined"
                        type="number"
                        value={sampleGroupForm.numberOfSamplesInGroup}
                        onChange={handleNumberOfSamplesInGroupChange}
                        InputProps={{ inputProps: { min: 1 } }}
                        disabled={isLoadingAddSampleGroup}
                        size="small"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <EntitySelect
                        label="Test Type"
                        entities={testTypes?.filter((t) => t.isActive)}
                        isLoadingEntities={isLoadingSites}
                        selectedValues={sampleGroupForm.testTypeId}
                        handleChange={handleTestTypeChange}
                        disabled={isLoadingAddSampleGroup}
                        size="small"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <EntitySelect
                        label="Composite Type"
                        entities={compositeTypes?.filter((c) => c.isActive)}
                        isLoadingEntities={isLoadingSites}
                        selectedValues={sampleGroupForm.compositeTypeId}
                        handleChange={handleCompositeTypeChange}
                        disabled={isLoadingAddSampleGroup}
                        size="small"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <UserSelect
                        userGroup={UserGroup.QualityControl}
                        userRole={UserRole.Operator}
                        handleUserChange={handleOperatorChange}
                        selectedUser={sampleGroupForm.operator}
                        disabled={isLoadingAddSampleGroup}
                        size="small"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <UserSelect
                        userGroup={UserGroup.QualityControl}
                        userRole={UserRole.Sampler}
                        handleUserChange={handleSamplerChange}
                        selectedUser={sampleGroupForm.sampler}
                        disabled={isLoadingAddSampleGroup}
                        size="small"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <UserSelect
                        userGroup={UserGroup.QualityControl}
                        userRole={UserRole.LabTech}
                        handleUserChange={handleLabTechChange}
                        selectedUser={sampleGroupForm.labTech}
                        disabled={isLoadingAddSampleGroup}
                        size="small"
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <AsyncButton
                    isLoading={isLoadingAddSampleGroup}
                    disabled={!isFormValid || isLoadingAddSampleGroup}
                    onClick={handleAddSampleGroup}
                    text="Create Samples"
                    isFullWidth
                    color="primary"
                />
            </Grid>
        </Grid>
    );
};
